<template>
  <div class="hello">
    <van-sticky>
      <van-nav-bar
        :title="pageTitle"
        :left-text="leftText"
        left-arrow
        @click-left="onClickLeft"
        @click-right="onClickRight"
      >
        <template #right v-if="act == 'user'">
          <van-icon name="search" size="18" />
        </template>
      </van-nav-bar>
      <van-tabs
        v-model:active="activeName"
        @click-tab="onClickTab"
        sticky
        v-if="act == 'user' && tabArr.length > 1 && viewTabs"
        class="countBox"
      >
        <van-tab
          :title="item.title"
          :name="item.tag"
          :key="idx"
          v-for="(item, idx) in tabArr"
        ></van-tab>
      </van-tabs>

      <template v-if="act == 'commission'">
        <div class="balanceBox">
          <div class="total">￥{{ shareCount }}</div>
        </div>
        <div class="countBox" style="text-align: left">
          <van-tag
            round
            type="primary"
            v-for="(item, idx) in dateArr"
            @click="clickTab(idx)"
            :plain="activeTab == idx ? false : true"
            :key="idx"
            size="large"
            style="margin: 0 6px"
            >{{ item.title }}</van-tag
          >
        </div>
      </template>
      <template v-if="act == 'user'">
        <div class="countBox" style="text-align: left">
          <van-tag
            round
            type="primary"
            v-for="(item, idx) in dateArr"
            @click="clickTab(idx)"
            :plain="activeTab == idx ? false : true"
            :key="idx"
            size="large"
            style="margin: 0 6px"
            >{{ item.title }}</van-tag
          >
        </div>
      </template>
    </van-sticky>
    <!-- 筛选框 -->
    <van-popup
      v-model:show="showPopup"
      round
      position="bottom"
      :style="{ minHeight: '30%' }"
    >
      <div style="margin-top: 16px">
        <van-cell-group inset label-width="auto">
          <van-field
            v-model="searchForm.keyword"
            label="关键词"
            placeholder="请输入用户名"
          />
        </van-cell-group>
        <div style="margin: 16px">
          <van-button
            round
            block
            type="primary"
            native-type="submit"
            @click="toSearch"
            >查询</van-button
          >
        </div>
      </div>
    </van-popup>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        :loading="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="getDataList"
      >
        <!-- 余额日志 -->
        <template v-if="act == 'commission'">
          <div
            class="balance"
            v-for="(item, idx) in dataList"
            :key="idx"
            @click="onClickItem(item)"
          >
            <div>
              <div class="title">
                <van-tag type="success">{{ item.type_txt }}</van-tag>
                {{ item.remark ?? "-" }}
              </div>
              <div class="times">{{ item.create_time }}</div>
            </div>
            <div class="price" v-if="parseFloat(item.amount) > 0">
              {{ item.amount }}
            </div>
            <div class="price" style="color: #14bf31" v-else>
              {{ item.amount }}
            </div>
          </div>
        </template>
        <!-- 我的分享 -->
        <template v-if="act == 'share'">
          <div
            class="user_list"
            v-for="(item, idx) in dataList"
            :key="idx"
            @click="onClickItem(item)"
          >
            <div class="avatar">
              <img :src="item.avatar" />
            </div>
            <div class="head">
              <div style="text-align: left">
                <div class="title">{{ item.nickname }}</div>
                <div class="times">
                  标识
                  <van-tag
                    style="margin-right: 4px"
                    v-if="item.auth == 1"
                    round
                    type="success"
                    >证</van-tag
                  >
                  <van-tag
                    style="margin-right: 4px"
                    v-else
                    round
                    color="#aaaaaa"
                    >未认证</van-tag
                  >
                  <van-tag
                    round
                    v-if="item.grade > 0"
                    style="margin-right: 4px"
                    type="warning"
                    >团</van-tag
                  >
                  <van-tag
                    round
                    v-if="item.is_zb > 0"
                    style="
                      margin-right: 4px;
                      background-color: rgba(18, 163, 220, 0.8);
                    "
                    type="warning"
                    >主</van-tag
                  >
                  <van-tag
                    round
                    v-if="item.black > 0"
                    style="
                      margin-right: 4px;
                      background-color: rgba(0, 0, 0, 0.6);
                    "
                    type="warning"
                    >黑</van-tag
                  >
                </div>
                <div class="mark">
                  {{
                    item.ip_info
                      ? item.ip_info.province +
                        "." +
                        item.ip_info.city +
                        "." +
                        item.ip_info.area
                      : "-"
                  }}
                </div>
                <div class="times">{{ item.create_time }}</div>
              </div>
              <div class="infos">
                <div class="price">
                  {{ item.countInfo.prizeCount }} /
                  {{ item.countInfo.prizeTotal }}
                </div>
                <div>
                  {{ item.countInfo.viewCount }} / {{ item.countInfo.times }}
                </div>
              </div>
            </div>
          </div>
        </template>
        <!-- 我的客户 -->
        <template v-if="act == 'user'">
          <div
            class="user_list"
            v-for="(item, idx) in dataList"
            :key="idx"
            @click="onClickItem(item)"
          >
            <div class="avatar">
              <img :src="item.avatar" />
            </div>
            <div class="head">
              <div style="text-align: left">
                <div class="title">
                  {{ item.nickname }}
                </div>
                <!-- <div class="times">
                  红包：{{ item.countInfo.prizeCount }} /
                  {{ item.countInfo.prizeTotal }}
                </div>
                <div class="times">
                  观看：{{ item.countInfo.viewCount }} /
                  {{ item.countInfo.times }}
                </div> -->
                <div class="times">{{ item.create_time }}</div>
              </div>
            </div>
          </div>
        </template>
        <template v-if="act == 'prize'">
          <!-- 答题奖励 弃用 -->
          <div class="qlist" v-for="item in dataList" :key="item.id">
            <div class="text">
              {{ item.title }}<br />
              <span class="answer">a{{ item.create_time }}</span>
            </div>
            <div class="amount">￥{{ item.amount }}</div>
          </div>
        </template>
      </van-list>
    </van-pull-refresh>
  </div>
</template>
  
<script>
import wx from "weixin-js-sdk"; // 引入微信JS-SDK库
import { showToast } from "vant";
export default {
  name: "RowList",

  props: {
    act: {
      type: String,
      default: "",
    },
    pageTitle: {
      type: String,
      default: "标题",
    },
    leftText: {
      type: String,
      default: "返回",
    },
    table: {
      type: String,
      default: "",
    },
  },
  setup(props, { emit }) {
    function onCallBack(val) {
      emit("callBack", val);
    }
    function clickRightCallBack(val) {
      emit("clickRight", val);
    }
    return { onCallBack, clickRightCallBack };
  },
  data() {
    return {
      activeName: "all",
      viewTabs: false,
      userInfo: false,
      loading: false,
      finished: false,
      refreshing: false,
      error: false,
      showRechargeBox: false,
      dataList: [],
      per_page: 15,
      last_page: 0,
      current_page: 0,
      total: 0,
      showPopup: false,
      rechargeData: {
        amount: "",
      },
      searchForm: {
        tag: "today",
        keyword: "",
        actTag: "",
      },
      shareCount: 0,
      activeTab: 0,
      countUrl: false,
      tabArr: [],
      dateArr: [
        {
          title: "今天",
          tag: "today",
        },
        {
          title: "昨天",
          tag: "yesterday",
        },
        {
          title: "本周",
          tag: "week",
        },
        {
          title: "本月",
          tag: "month",
        },
        {
          title: "全部",
          tag: "all",
        },
      ],
    };
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.searchForm.actTag = this.act;
    if (this.act == "order") {
      this.searchForm.scence = "orderList";
    } else if (this.act == "user") {
      this.searchForm.scence = "myUser";
      this.searchForm.date_tag = "today";
      let tmp = false;
      Object.keys(this.userInfo.worker).map((key) => {
        tmp = this.userInfo.worker[key];
        if (tmp.is_sale == 1) {
          this.tabArr.push({
            tag: tmp.store_id,
            title: tmp.store_title,
          });
        }
      });
      this.activeName = this.searchForm.tag =
        this.tabArr.length > 0 ? this.tabArr[0].tag : "all";
      if (this.tabArr.length < 2) {
        this.tabArr = [];
      }
    } else if (this.act == "storeMoney") {
      this.searchForm.scence = "storeMoney";
      this.searchForm.date_tag = "today";
      let tmp = false;
      Object.keys(this.userInfo.worker).map((key) => {
        tmp = this.userInfo.worker[key];
        if (tmp.is_store == 1) {
          this.tabArr.push({
            tag: tmp.store_id,
            title: tmp.store_title,
          });
        }
      });
      this.activeName = this.searchForm.tag =
        this.tabArr.length > 0 ? this.tabArr[0].tag : "all";
      if (this.tabArr.length < 2) {
        this.tabArr = [];
      }
      console.log(this.tabArr, this.userInfo.worker);
    } else if (this.act == "commission") {
      this.searchForm.scence = "commission";
    }
    window.document.title = this.pageTitle;
    this.viewTabs = true;

    this.onRefresh();
  },
  methods: {
    getUserInfo() {
      this.$http.postData("/User/getUserInfo", {}).then((res) => {
        localStorage.setItem("userInfo", JSON.stringify(res));
        this.userInfo = res;
      });
    },
    onRefresh() {
      this.dataList = [];
      this.current_page = 0;
      this.refreshing = true;
      this.finished = false;
      this.getDataList();
    },
    toStartLive(item) {
      this.$router.push({
        path: "/live/push",
        query: {
          rid: item.id,
        },
      });
    },
    onClickTab(val) {
      this.activeName = val.name;
      if (this.act == "user") {
        this.searchForm.tag = val.name;
      } else if (this.act == "commission") {
        this.searchForm.tag = val.name;
      }
      this.onRefresh();
    },
    clickTab(idx) {
      this.activeTab = idx;
      if (this.act == "user") {
        this.searchForm.date_tag = this.dateArr[idx].tag;
      } else if (this.act == "commission") {
        this.searchForm.tag = this.dateArr[idx].tag;
      }
      this.onRefresh();
    },
    onClickLeft() {
      this.$router.go(-1);
      return;
      this.$router.push({ path: "/" });
    },
    onClickRight() {
      if (this.act == "share" || this.act == "user") {
        // 弹出搜索框
        this.showPopup = true;
      }
    },
    onClickItem(val) {
      this.onCallBack(val);
    },
    toSearch() {
      this.dataList = [];
      this.current_page = 0;
      this.refreshing = true;
      this.showPopup = false;
      this.getDataList();
    },
    getDataList() {
      if (this.inLoad) {
        return;
      }
      if (!this.table || this.table == "") {
        showToast("参数错误");
        return;
      }
      this.inLoad = true;
      this.$http
        .postData("/RowList/getList", {
          table: this.table,
          pageInfo: {
            page_size: this.per_page,
          },
          page: this.current_page + 1,
          search: { ...this.searchForm },
          hide_success: true,
        })
        .then((res) => {
          this.inLoad = false;
          this.loading = false;
          this.refreshing = false;

          res.data.map((item) => {
            this.dataList.push(item);
          });
          this.current_page = res.current_page;
          this.total = res.total;

          if (res.last_page == res.current_page || res.last_page == 0) {
            this.finished = true;
          }
          if (res.current_page == 1) {
            if (this.act == "commission") {
              this.getDataCount({
                scence: "commission",
                table: "Commission",
              });
            }
          }
        })
        .catch((error) => {
          this.inLoad = false;
          this.loading = false;
          this.finished = true;
          this.refreshing = false;
        });
    },
    getDataCount(tmp) {
      this.$http
        .postData("/RowList/getDataCount", {
          scence: tmp.scence,
          table: tmp.table,
          search: this.searchForm,
          hide_loading: true,
          hide_success: true,
          hide_erro: true,
        })
        .then((res) => {
          this.shareCount = res;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    rechargeDo() {
      // if(!this.isWx()){
      //   showToast('请在微信中操作');
      //   return
      // }
      if (parseInt(this.rechargeData.amount) <= 0) {
        showToast("请确认充值金额");
        return;
      }
      this.$http
        .postData("/Recharge/applyOrder", {
          amount: this.rechargeData.amount,
          hide_success: false,
        })
        .then((res) => {
          this.rechargeData.amount = "";
          this.showRechargeBox = false;
          this.pullPay(res);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
  <style scoped>
.user_list {
  margin: 10px;
  background-color: #ffffff;
  font-size: 14px;
  border-radius: 6px;
  box-shadow: 0 0px 4px 0 rgba(0, 0, 0, 0.3);
  position: relative;
}
.user_list .head {
  padding-left: 60px;
  display: flex;
  justify-content: space-between;
  padding-top: 4px;
  padding-bottom: 4px;
}
.user_list .head .title {
  font-size: 14px;
  color: #000000;
  line-height: 30px;
  height: 30px;
}
.user_list .head .times {
  font-size: 12px;
  line-height: 20px;
  height: 20px;
  color: #666666;
}
.user_list .head .mark {
  font-size: 12px;
  line-height: 20px;
  height: 20px;
  color: #666666;
}
.user_list .avatar {
  position: absolute;
  left: 15px;
  top: 10px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.user_list .avatar img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.balance {
  margin: 10px;
  background-color: #ffffff;
  font-size: 12px;
  border-radius: 6px;
  box-shadow: 0 0px 4px 0 rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: space-between;
  padding: 4px 10px;
}
.balance .title {
  font-size: 12px;
  color: #000000;
  line-height: 20px;
  height: 20px;
}
.balance .times {
  font-size: 12px;
  line-height: 20px;
  height: 20px;
  color: #666666;
}
.balance .price {
  color: #cc0000;
  line-height: 40px;
  font-size: 14px;
}
.infos {
  text-align: right;
  font-size: 12px;
  padding-right: 15px;
  line-height: 20px;
}
.infos .price {
  color: #cc0000;
  line-height: 30px;
  height: 30px;
  font-size: 14px;
}

.video_list {
  position: relative;
  padding: 10px;
  margin: 10px;
  min-height: 100px;
  padding-left: 120px;
  background-color: #ffffff;
  font-size: 14px;
  margin-top: 20px;
  border-radius: 6px;
  box-shadow: 0 0px 4px 0 rgba(0, 0, 0, 0.3);
}
.video_list .title {
  font-size: 1rem;
  line-height: 30px;
}
.video_list .desc {
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: #666666;
}
.video_list .avatar {
  width: 100px;
  height: 100px;
  position: absolute;
  left: 10px;
  top: 10px;
  border-radius: 10px;
  overflow: hidden;
}
.play {
  position: absolute;
  z-index: 1;
  top: 10px;
  left: 10px;
  right: 0px;
  height: 100px;
  width: 100px;
  color: #ffffff;
  font-size: 40px;
  text-align: center;
  line-height: 100px;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.3);
}
.live_tag {
  position: absolute;
  z-index: 1;
  top: 16px;
  left: 10px;
  right: 0px;
}
/* 
  .video_list{
    position: relative;
    padding:10px;
    margin:10px;
    background-color: #ffffff;
    font-size: 14px;
    margin-top:20px;
    border-radius: 10px;
			box-shadow: 0 0px 20px 0 rgba(0, 0, 0, 0.3);
  }
  .video_list .title{
    font-size: 1rem;
    line-height: 30px;
  }
  .video_list .desc{
    font-size: 0.8rem;
    line-height: 30px;
    color: #666666;
  }
  .video_list .avatar{
    width:100%;
    height: 160px;
  }
  .video_list .content{
    color: #666666;
    line-height: 24px;
    padding:10px;
    font-size: 0.8rem;
  }
  .play{
    position: absolute;
    z-index: 1;
    top:0px;
    left:0px;
    right:0px;
    height: 100%;
    color: #ffffff;
    font-size: 50px;
    text-align: center;
    line-height: 160px;
  } */

.van-cell {
  padding: 0;
  height: 40px;
  line-height: 40px;
}
.van-radio {
  padding: 0;
  height: 40px;
  line-height: 40px;
}

.countBox {
  padding: 10px;
  background-color: #fff;
  text-align: center;
  box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.3);
}

.qlist {
  padding: 10px;
  margin: 10px;
  margin-top: 0px;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  border-radius: 10px;
  box-shadow: 0 0px 20px 0 rgba(0, 0, 0, 0.3);
}
.qlist .text {
  font-size: 14px;
  line-height: 30px;
}
.qlist .answer {
  color: #888888;
}
.qlist .amount {
  font-size: 14px;
  line-height: 60px;
  color: #cc0000;
}
.balanceBox {
  background: linear-gradient(135deg, #2770ba 0%, #00a1d3 40%) no-repeat;
  min-height: 30px;
  padding: 40px 20px;
}
.balanceBox .total {
  font-size: 40px;
  color: #ffffff;
}
.balanceBox .small {
  font-size: 14px;
  color: #ffffff;
  padding-right: 10px;
}
.balanceBox .recharge {
  display: block;
  width: auto;
  text-align: center;
  border-radius: 6px;
  line-height: 20px;
  padding: 4px 14px;
  color: #ffffff;
  position: absolute;
  z-index: 2;
  right: 20px;
  top: 76px;
  font-size: 14px;
  background: linear-gradient(135deg, #e49f29 0%, #e59408 40%) no-repeat;
}
</style>
  