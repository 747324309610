<template>
  <div class="hello" style="background-color: #f3f3f3; min-height: 100vh">
    <van-sticky>
      <van-nav-bar
        :title="pageTitle"
        left-text="返回"
        left-arrow
        @click-left="onClickLeft"
        @click-right="onClickRight"
      >
        <template #right>
          <van-button
            size="small"
            @click="addOrderPrice"
            type="primary"
            v-if="userType == 'store' || userType == 'help'"
            >加项目</van-button
          >
        </template>
      </van-nav-bar>
    </van-sticky>

    <div v-if="hasLoad" style="padding-bottom: 140px">
      <van-tabs v-model:active="activeTab" @click-tab="onClickTab">
        <van-tab name="base" title="订单信息">
          <van-cell-group inset v-if="!disabled">
            <!-- 店长可修改 -->
            <van-cell
              title="订单来源"
              :value="orderDetail.from_txt"
              v-if="userType != 'user'"
            />
            <van-cell title="订单号" :value="orderDetail.order_sn" />
            <van-cell title="所属门店" :value="orderDetail.store_title" />
            <van-cell title="创建时间" :value="orderDetail.create_time" />
            <van-cell title="状态">
              <template #value>
                <van-tag type="danger">{{ orderDetail.status_txt }}</van-tag>
              </template>
            </van-cell>
            <van-cell title="服装">
              <template #value>
                <van-stepper
                  v-model="orderDetail.clothes"
                  :disabled="disabled"
                  :min="1"
                  :max="9999"
                />
              </template>
            </van-cell>
            <van-cell title="保底拍摄">
              <template #value>
                <van-stepper
                  v-model="orderDetail.pic_num"
                  :disabled="disabled"
                  :min="1"
                  :max="9999"
                />
              </template>
            </van-cell>
            <van-cell title="精修张数">
              <template #value>
                <van-stepper
                  v-model="orderDetail.ps_num"
                  :disabled="disabled"
                  :min="1"
                  :max="9999"
                />
              </template>
            </van-cell>
            <van-cell title="套餐金额">
              <template #value>
                <van-stepper
                  :disabled="disabled"
                  v-model="orderDetail.base_price"
                  @change="changeBasePrice"
                  :min="1"
                  :max="9999"
                />
              </template>
            </van-cell>
            <van-cell title="加精修" :value="orderDetail.add_price" />
            <van-cell title="总金额" :value="orderDetail.total" />
            <van-field
              label="订单备注"
              type="textarea"
              rows="2"
              :disabled="disabled"
              v-model="orderDetail.remark"
              placeholder="请填写备注"
              maxlength="50"
              show-word-limit
            />
          </van-cell-group>
          <van-cell-group v-else>
            <van-cell
              title="订单来源"
              :value="orderDetail.from_txt"
              v-if="userType != 'user'"
            />
            <van-cell title="订单号" :value="orderDetail.order_sn" />
            <van-cell title="所属门店" :value="orderDetail.store_title" />
            <van-cell title="创建时间" :value="orderDetail.create_time" />
            <van-cell title="状态">
              <template #value>
                <van-tag type="danger">{{ orderDetail.status_txt }}</van-tag>
              </template>
            </van-cell>
            <van-cell title="服装" :value="orderDetail.clothes + '(套)'" />
            <van-cell title="保底拍摄" :value="orderDetail.pic_num + '(张)'" />
            <van-cell
              title="精修张数"
              :value="orderDetail.ps_num + orderDetail.add_num + '(张)'"
            />
            <van-cell
              title="订单备注"
              :value="orderDetail.remark ? orderDetail.remark : '-'"
            />
            <van-cell title="套餐金额" :value="orderDetail.base_price" />
            <van-cell title="加精修" :value="orderDetail.add_price" />
            <van-cell title="加项目" @click="showXmPop = true">
              <template #value>
                <span class="red">￥{{ orderDetail.xm_total }}</span>
              </template>
            </van-cell>
            <van-cell title="总金额"
              ><template #value>
                <span>￥{{ orderDetail.total }}</span>
              </template></van-cell
            >
            <van-cell title="实收"
              ><template #value>
                <span class="red">￥{{ orderDetail.act_total }}</span>
              </template></van-cell
            >
          </van-cell-group>
          <van-cell-group title="其他">
            <van-cell
              title="销售"
              is-link
              v-if="orderDetail.worker.sale"
              :value="orderDetail.worker.sale.show_name"
              @click="viewWorker(orderDetail.sale_uid)"
            />
            <van-cell
              title="客服"
              is-link
              v-if="orderDetail.worker.kefu"
              :value="orderDetail.worker.kefu.show_name"
              @click="viewWorker(orderDetail.kefu_uid)"
            />
          </van-cell-group>
        </van-tab>
        <van-tab name="users" title="客户信息">
          <van-cell-group
            v-for="(item, idx) in orderDetail.clients"
            :key="idx"
            :title="'客户' + (idx + 1)"
            label-width="auto"
          >
            <template #title>
              <span style="line-height: 24px; margin-right: 10px"
                >客户{{ idx + 1 }}</span
              >
              <van-tag type="warning" v-if="idx == 0">联系人</van-tag>
              <van-button
                size="mini"
                @click="delClient(idx)"
                type="default"
                style="float: right"
                v-if="!disabled"
                >删除</van-button
              >
            </template>
            <template v-if="disabled">
              <van-cell title="称呼" :value="item.name" />
              <van-cell title="联系电话" :value="item.mobile">
                <template #value>
                  <a style="color: #666666" :href="'tel:' + item.mobile">{{
                    item.mobile
                  }}</a>
                </template>
              </van-cell>
              <van-cell title="服装" :value="item.clothes">
                <template #value> {{ item.clothes }}套 </template>
              </van-cell>
              <van-cell title="底片" :value="item.pic_num">
                <template #value> {{ item.pic_num }}张 </template>
              </van-cell>
              <van-cell title="精修" :value="item.ps_num">
                <template #value> {{ item.ps_num }}张 </template>
              </van-cell>
              <van-cell title="费用" :value="item.price">
                <template #value> {{ item.price }}元</template>
              </van-cell>
              <template v-if="userType == 'store' || userType == 'help'">
                <van-field
                  label="化妆师"
                  v-model="item.makeup_name"
                  v-if="item.need_makeup"
                  is-link
                  readonly
                  placeholder="请选择"
                  input-align="right"
                  @click="showWorkerPickerBefore('makeup', idx)"
                />
                <van-field
                  label="化妆师"
                  v-model="item.makeup_name"
                  v-else
                  readonly
                  disabled
                  placeholder="不需要化妆"
                  input-align="right"
                  @click="showToast('不需要化妆')"
                />
                <van-field
                  label="摄影师"
                  v-model="item.shoot_name"
                  is-link
                  readonly
                  placeholder="请选择"
                  input-align="right"
                  @click="showWorkerPickerBefore('shoot', idx)"
                />
                <van-field
                  label="选片师"
                  v-model="item.choose_name"
                  is-link
                  readonly
                  placeholder="请选择"
                  input-align="right"
                  @click="showWorkerPickerBefore('choose', idx)"
                />
              </template>
              <template v-else>
                <van-field
                  label="化妆师"
                  v-model="item.makeup_name"
                  is-link
                  readonly
                  :placeholder="userType == 'store' ? '请选择' : '暂无'"
                  input-align="right"
                  @click="viewWorker(item.makeup_uid)"
                />
                <van-field
                  label="摄影师"
                  v-model="item.shoot_name"
                  is-link
                  readonly
                  :placeholder="userType == 'store' ? '请选择' : '暂无'"
                  input-align="right"
                  @click="viewWorker(item.shoot_uid)"
                />
                <van-field
                  label="选片师"
                  v-model="item.choose_name"
                  is-link
                  readonly
                  :placeholder="userType == 'store' ? '请选择' : '暂无'"
                  input-align="right"
                  @click="viewWorker(item.choose_uid)"
                />
              </template>
            </template>
            <template v-else>
              <van-field
                label="称呼"
                type="text"
                v-model="item.name"
                maxlength="8"
                :disabled="disabled"
                placeholder="请填写称呼"
              />
              <van-field
                label="联系电话"
                type="digit"
                v-model="item.mobile"
                maxlength="11"
                :disabled="disabled"
                placeholder="请填写电话"
              />
              <van-cell title="化妆师" :value="item.makeup_name" />
              <van-cell title="摄影师" :value="item.shoot_name" />
              <van-cell title="选片师" :value="item.choose_name" />
            </template>
          </van-cell-group>
          <div
            style="
              text-align: center;
              line-height: 20px;
              color: #666666;
              font-size: 12px;
              margin: 10px;
            "
            v-if="!this.orderDetail.clients || !this.orderDetail.clients.length"
          >
            请添加客户信息
          </div>

          <div style="margin: 20px 40px" v-if="!disabled">
            <van-button size="small" block type="warning" @click="addClient"
              >添加客户</van-button
            >
          </div>
        </van-tab>
        <van-tab name="logs" title="操作日志">
          <van-steps
            direction="vertical"
            :active="0"
            v-if="
              userType == 'help' || userType == 'store' || userType == 'kefu'
            "
          >
            <van-step v-for="(log, idx) in orderLog" :key="idx">
              <div>
                {{ log.title }}
                <span v-if="log.content">({{ log.content }})</span>
              </div>
              <div>{{ log.act_user.name }} {{ log.create_time }}</div>
            </van-step>
          </van-steps>

          <van-steps
            direction="vertical"
            :active="0"
            v-else-if="userType == 'user'"
          >
            <template v-for="(log, idx) in orderLog">
              <van-step :key="idx" v-if="log.act_tag != 'changeCommission'">
                <div>
                  {{ log.title }}{{ log.act_tag }}
                  <span v-if="log.content">({{ log.content }})</span>
                </div>
                <div>{{ log.act_user.name }} {{ log.create_time }}</div>
              </van-step>
            </template>
          </van-steps>
        </van-tab>
        <van-tab name="commission" v-if="userType == 'store'" title="提成明细">
          <div
            style="
              font-size: 12px;
              height: 30px;
              line-height: 30px;
              text-align: center;
            "
          >
            <span>总金额：{{ orderDetail.total }}</span>
            <span style="margin: 0 10px"
              >实收：{{ orderDetail.act_total }}</span
            >
            <span style="margin: 0 10px"
              >提成：-{{ orderDetail.money_info.commission }}</span
            >
            <span class="red">结余：{{ orderDetail.money_info.last }}</span>
          </div>
          <van-cell
            title="联系电话"
            v-for="(commission, idx) in commissionLog"
            :key="idx"
          >
            <template #title>
              {{ commission.name }}
              <van-tag type="warning">{{ commission.type_txt }}</van-tag>
            </template>
            <template #value>
              <span style="color: #cc0000; font-size: 14px"
                >￥{{ commission.amount }}</span
              >
            </template>
            <template #label>
              <div>
                <div>
                  <span>{{ commission.remark }}</span>
                </div>
                <div>
                  <span>{{ commission.create_time }}</span>
                </div>
              </div>
            </template>
          </van-cell>
        </van-tab>
      </van-tabs>
    </div>

    <div class="btnbox" v-if="btnArr && btnArr.length">
      <van-button
        :disabled="loading"
        round
        block
        @click="doNext(btn)"
        v-for="(btn, idx) in btnArr"
        :key="idx"
        :type="btn.type"
        style="margin-bottom: 6px"
        >{{ btn.title }}</van-button
      >
    </div>
    <!-- 选择员工 -->
    <van-popup
      v-model:show="showWorkerPicker"
      round
      position="bottom"
      :style="{ minHeight: '30%', maxHeight: '60%' }"
      @cancel="showWorkerPicker = false"
    >
      <div style="margin-top: 16px">
        <van-cell v-for="(user, idx) in tmpWorkerArr" :key="idx">
          <template #title>
            {{ user.show_name }}
          </template>
          <template #right-icon>
            <van-button
              @click="selectWorker(user)"
              icon="guide-o"
              size="small"
              type="success"
              >指派</van-button
            >
          </template>
        </van-cell>
      </div>
    </van-popup>
    <!-- 查看加项目内容 -->
    <van-popup
      v-model:show="showXmPop"
      round
      position="bottom"
      :style="{ minHeight: '30%', maxHeight: '60%' }"
      @cancel="showXmPop = false"
    >
      <div style="margin-top: 16px">
        <van-cell
          v-for="(item, idx) in orderDetail.xm"
          :title="item.title"
          :key="idx"
        >
          <template #title>
            {{ item.title }}
          </template>
          <template #value>
            <span>￥{{ item.price }}</span>
          </template>
        </van-cell>
      </div>
    </van-popup>
    <!-- 上传收款凭证 -->
    <van-popup
      v-model:show="showPayPopup"
      round
      position="bottom"
      :style="{ minHeight: '30%', maxHeight: '60%' }"
    >
      <div style="text-align: center; padding: 20px 0">
        <van-uploader
          v-model="fileList"
          :preview-image="true"
          :max-count="1"
          :after-read="afterRead"
        ></van-uploader>
        <div>
          <van-button type="primary" @click="sendPayPic"
            >提交付款截图</van-button
          >
        </div>
      </div>
    </van-popup>

    <!-- 查看员工信息 -->
    <van-popup
      v-model:show="showViewWorker"
      round
      :style="{ minHeight: '30%', maxHeight: '60%', width: '80%' }"
    >
      <div style="margin-top: 16px" v-if="viewWorkerData">
        <div style="text-align: center">
          <van-image
            :src="
              viewWorkerData.avatar
                ? viewWorkerData.avatar
                : viewWorkerData.user_avatar
            "
            class="header"
          ></van-image>
        </div>
        <van-cell title="工号" :value="viewWorkerData.num"></van-cell>
        <van-cell title="姓名" :value="viewWorkerData.show_name"></van-cell>
        <van-cell title="电话" :value="viewWorkerData.mobile">
          <template #value>
            <a style="color: #666666" :href="'tel:' + viewWorkerData.mobile">{{
              viewWorkerData.mobile
            }}</a>
          </template>
        </van-cell>
      </div>
    </van-popup>

    <!-- 评分 -->
    <van-popup
      v-model:show="showRatePopup"
      round
      position="bottom"
      :style="{ minHeight: '30%', maxHeight: '60%' }"
    >
      <div style="margin-top: 16px" v-if="rateArr">
        <van-cell v-for="(item, idx) in rateArr" :key="idx">
          <template #title>
            {{ item.title }}
          </template>
          <template #value>
            {{ item.txt }}
          </template>
          <template #label>
            <van-rate
              color="#ffd21e"
              @change="changeRate(idx)"
              v-model="item.value"
            />
          </template>
        </van-cell>
      </div>
      <div style="text-align: center; margin: 10px 0">
        <van-button :disabled="loading" type="primary" @click="sendRate"
          >提交反馈</van-button
        >
      </div>
    </van-popup>
  </div>
</template>
  
<script>
import { ref } from "vue";
import { showConfirmDialog, showToast } from "vant";
export default {
  name: "orderDetail",
  data() {
    return {
      loading: false,
      pageTitle: "订单详情",
      activeTab: "base",
      showWorkerPicker: false,
      workerIdx: 0,
      workerType: "makeup",
      oid: 0,
      userType: false,
      orderDetail: false,
      hasLoad: false,
      showPopup: false,
      showPayPopup: false,
      showRatePopup: false,
      rateArr: false,
      disabled: true,
      tmpWorkerArr: [], //修图师
      orderLog: [],
      commissionLog: [],
      btnArr: [],
      pay_pic: false,
      showXmPop: false,
      price: 0,
      can_select_makeup: false,
      can_select_shoot: false,
      can_select_choose: false,
      showViewWorker: false,
      viewWorkerData: false,
    };
  },
  setup() {
    const fileList = ref([]);
    return {
      fileList,
    };
  },
  mounted() {
    this.oid =
      this.$route.params.oid != null && this.$route.params.oid
        ? this.$route.params.oid
        : false;
    this.userType =
      this.$route.params.userType != null && this.$route.params.userType
        ? this.$route.params.userType
        : false;
    if (!this.oid || !this.userType) {
      showToast("缺少必要参数");
      return;
    }

    this.getOrderDetail();
  },
  methods: {
    addOrderPrice() {
      // 加项目
      if (this.orderDetail.status < 15 || this.orderDetail.status > 50) {
        showToast("当前状态不支持");
        return;
      }
      this.$router.push(
        "/addPrice/" + this.userType + "/" + this.orderDetail.id
      );
    },
    groupedItems(arr, tag) {
      let pfx = tag + "_uid";
      let newData = {};
      arr.forEach((item) => {
        if (!newData[item[pfx]]) {
          newData[item[pfx]] = [];
        }
        newData[item[pfx]].push({ ...item });
      });
      return newData;
    },
    onClickTab(val) {
      if (val.name == "logs") {
        this.orderLog = this.orderDetail.order_log;
      }
      if (val.name == "commission") {
        this.commissionLog = [];
        this.$http
          .postData("/Commission/getOrderCommission", {
            order_id: this.orderDetail.id,
          })
          .then((res) => {
            this.commissionLog = res;
          })
          .catch((error) => {});
      }
    },
    changeBasePrice(val) {
      this.orderDetail.total = val;
    },
    addClient() {
      this.orderDetail.clients.push({
        name: "",
        mobile: "",
      });
    },
    delClient(idx) {
      const beforeClose = (action) =>
        new Promise((resolve) => {
          resolve(action);
        });
      // 加一个确认
      showConfirmDialog({
        title: "提示",
        message: "确认删除该用户吗？",
        beforeClose,
      })
        .then((val) => {
          this.orderDetail.clients.splice(idx, 1);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    // onClickRight() {
    //   this.orderLog = this.orderDetail.order_log;
    //   this.showPopup = true;
    // },
    viewWorker(worker_id = 0) {
      if (!worker_id) {
        showToast("暂未指定");
        return;
      }
      // 查看员工信息
      this.viewWorkerData = false;
      this.$http
        .postData("/Worker/getWorkerDetail", {
          worker_id: worker_id,
        })
        .then((res) => {
          this.viewWorkerData = res;
          this.showViewWorker = true;
        })
        .catch((error) => {});
    },
    getOrderDetail() {
      this.btnArr = [];
      this.$http
        .postData("/Order/getDetail", {
          oid: this.oid,
          userType: this.userType,
        })
        .then((res) => {
          this.disabled = true;
          this.orderDetail = res;
          this.fileList = [];
          this.price = res.price;
          this.btnArr = res.btnArr ? res.btnArr : [];
          this.can_select_makeup = res.can_select_makeup;
          this.can_select_shoot = res.can_select_shoot;
          this.can_select_choose = res.can_select_choose;
          this.hasLoad = true;
        })
        .catch((error) => {});
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    afterRead(file, name) {
      let params = new FormData(); //创建form对象
      params.append("files", file.file);
      this.$http
        .uploadPic("/Upload/uploadFile", params)
        .then((res) => {
          this.pay_pic = res.url;
        })
        .catch((error) => {});
    },
    showWorkerPickerBefore(type, idx) {
      this.workerIdx = idx;
      this.workerType = type;
      if (type == "makeup" && !this.can_select_makeup) {
        showToast("不可操作");
        return;
      } else if (type == "shoot" && !this.can_select_shoot) {
        showToast("不可操作");
        return;
      } else if (type == "choose" && !this.can_select_choose) {
        showToast("不可操作");
        return;
      }
      this.getWorkerListDiy();
    },
    getWorkerListDiy() {
      this.$http
        .postData("/Worker/getWorkerListDiy", {
          store_id: this.orderDetail.store_id,
          type: this.workerType,
        })
        .then((res) => {
          this.tmpWorkerArr = res;
          this.showWorkerPicker = true;
        })
        .catch((error) => {});
    },
    selectWorker(user) {
      if (this.workerType == "ps") {
        this.sendPsDo(user);
      } else if (this.workerType == "kefu") {
        this.sendKefuDo(user);
      } else {
        let obj = this.orderDetail.clients[this.workerIdx];
        obj[this.workerType + "_name"] = user.show_name;
        obj[this.workerType + "_uid"] = user.id;
        this.showWorkerPicker = false;
      }
    },
    changeRate(idx) {
      let val = this.rateArr[idx].value;
      if (val == 1) {
        this.rateArr[idx].txt = "非常差";
      } else if (val == 2) {
        this.rateArr[idx].txt = "差";
      } else if (val == 3) {
        this.rateArr[idx].txt = "一般";
      } else if (val == 4) {
        this.rateArr[idx].txt = "满意";
      } else if (val == 5) {
        this.rateArr[idx].txt = "非常满意";
      }
    },
    sendRate() {
      try {
        this.rateArr.forEach((item, idx) => {
          if (parseInt(item.value) == 0) {
            throw new Error("全部项目都需要评分哦");
          }
        });
      } catch (error) {
        showToast(error.message);
        return;
      }
      if (this.loading) {
        return;
      }
      this.loading = true;
      let _this = this;
      this.$http
        .postData("/Order/orderAction", {
          oid: this.orderDetail.id,
          store_id: this.orderDetail.store_id,
          userType: this.userType,
          act_tag: "send_rate",
          saveData: this.rateArr,
        })
        .then((res) => {
          this.loading = false;
          this.showRatePopup = false;
          this.rateArr = false;
          setTimeout(function () {
            _this.getOrderDetail();
          }, 700);
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    sendPsDo(user) {
      const beforeClose = (action) =>
        new Promise((resolve) => {
          resolve(action);
        });
      // 加一个确认
      showConfirmDialog({
        title: "提示",
        message: `确认指派给【${user.show_name}】修图吗？`,
        beforeClose,
      })
        .then((val) => {
          this.$http
            .postData("/Order/orderAction", {
              oid: this.orderDetail.id,
              store_id: this.orderDetail.store_id,
              userType: this.userType,
              act_tag: "send_ps",
              saveData: {
                nickname: user.show_name,
                ps_uid: user.id,
              },
            })
            .then((res) => {
              this.loading = false;
              this.showWorkerPicker = false;
              this.tmpWorkerArr = [];
              this.getOrderDetail();
            })
            .catch((error) => {
              this.loading = false;
            });
          this.loading = false;
          return;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },
    sendKefuDo(user) {
      const beforeClose = (action) =>
        new Promise((resolve) => {
          resolve(action);
        });
      let _this = this;
      // 加一个确认
      showConfirmDialog({
        title: "提示",
        message: `确认指派【${user.show_name}】为该订单的客服吗？`,
        beforeClose,
      })
        .then((val) => {
          this.$http
            .postData("/Order/orderAction", {
              oid: this.orderDetail.id,
              store_id: this.orderDetail.store_id,
              userType: this.userType,
              act_tag: "send_kefu",
              hide_success: false,
              saveData: {
                nickname: user.show_name,
                kefu_uid: user.id,
              },
            })
            .then((res) => {
              this.loading = false;
              this.showWorkerPicker = false;
              this.tmpWorkerArr = [];
              setTimeout(function () {
                _this.getOrderDetail();
              }, 800);
            })
            .catch((error) => {
              this.loading = false;
            });
          this.loading = false;
          return;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },
    sendPayPic() {
      if (!this.pay_pic) {
        showToast("请上传收款截图");
        return;
      }
      const beforeClose = (action) =>
        new Promise((resolve) => {
          resolve(action);
        });
      // 加一个确认
      showConfirmDialog({
        title: "提示",
        message: `确认提交付款截图吗？`,
        beforeClose,
      })
        .then((val) => {
          this.$http
            .postData("/Order/orderAction", {
              oid: this.orderDetail.id,
              store_id: this.orderDetail.store_id,
              sale_uid: this.orderDetail.sale_uid,
              userType: this.userType,
              act_tag: "send_pay",
              saveData: {
                pay_pic: this.pay_pic,
              },
            })
            .then((res) => {
              this.loading = false;
              this.showPayPopup = false;
              this.getOrderDetail();
            })
            .catch((error) => {
              this.loading = false;
            });
          this.loading = false;
          return;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },
    doNext(btn) {
      if (this.loading) {
        return;
      }
      let tag = btn.tag;
      let tips = "";
      let stype = tag.split("_")[1];
      let saveData = false;
      if (tag == "send_makeup" || tag == "send_shoot" || tag == "send_choose") {
        if (
          (tag == "send_makeup" && !this.orderDetail.can_select_makeup) ||
          (tag == "send_shoot" && !this.orderDetail.can_select_shoot) ||
          (tag == "send_choose" && !this.orderDetail.can_select_choose)
        ) {
          showToast("不可操作");
          return;
        }
        let tit = "";
        if (stype == "makeup") {
          tit = "化妆师";
          tips = "确认指派化妆师吗？";
        } else if (stype == "shoot") {
          tit = "摄影师";
          tips = "确认指派摄影师吗？";
        } else if (stype == "choose") {
          tit = "选片师";
          tips = "确认指派选片师吗？";
        }
        // 指派化妆师
        try {
          this.orderDetail.clients.forEach((item, idx) => {
            if (stype == "makeup") {
              // 化妆师特殊处理
              if (
                item["need_makeup"] &&
                (item[stype + "_name"] == "" || !item[stype + "_uid"])
              ) {
                throw new Error("客户" + (idx + 1) + `，未选择${tit}`);
              }
            } else {
              if (item[stype + "_name"] == "" || !item[stype + "_uid"]) {
                throw new Error("客户" + (idx + 1) + `，未选择${tit}`);
              }
            }
          });
          saveData = this.orderDetail.clients;
        } catch (error) {
          showToast(error.message);
          this.activeTab = "users";
          return;
        }
      } else if (tag == "send_pay") {
        this.showPayPopup = true;
        return;
      } else if (tag == "send_old" || tag == "send_pro") {
        tips = tag == "send_old" ? "确认发送原片吗？" : "确认发送精修图吗？";
        saveData = true;
      } else if (tag == "send_ps") {
        this.workerType = "ps";
        this.getWorkerListDiy();
        return;
      } else if (tag == "send_kefu") {
        this.workerType = "kefu";
        this.getWorkerListDiy();
        return;
      } else if (tag == "edit_order") {
        saveData = {
          clothes: this.orderDetail.clothes,
          pic_num: this.orderDetail.pic_num,
          ps_num: this.orderDetail.ps_num,
          add_num: this.orderDetail.add_num,
          base_price: this.orderDetail.base_price,
          add_price: this.orderDetail.add_price,
          clients: this.orderDetail.clients,
          remark: this.orderDetail.remark,
        };
        if (!saveData.clients || saveData.clients.length < 1) {
          showToast("至少填写一名客户信息");
          return;
        }
        tips = "确认修改订单吗？";
        try {
          saveData.clients.forEach((item, idx) => {
            if (item.name == "") {
              throw new Error("客户" + (idx + 1) + ",称呼不能为空");
            }
            if (item.mobile == "") {
              throw new Error("客户" + (idx + 1) + ",电话不能为空");
            }
          });
        } catch (error) {
          showToast(error.message);
          return;
        }
      } else if (tag == "sure2_yes") {
        tips = "确认已收到款项？";
      } else if (tag == "sure2_no") {
        tips = "确认没有收到款项吗？";
      } else if (tag == "end_order") {
        tips = "确认要结束订单吗？";
      } else {
        showToast("未知操作");
        return;
      }
      const beforeClose = (action) =>
        new Promise((resolve) => {
          resolve(action);
        });
      // 加一个确认
      showConfirmDialog({
        title: "提示",
        message: tips,
        beforeClose,
      })
        .then((val) => {
          if (tag == "end_order") {
            this.$router.push("/endOrder/" + this.orderDetail.id);
            return;
          }

          this.loading = true;

          this.$http
            .postData("/Order/orderAction", {
              oid: this.orderDetail.id,
              store_id: this.orderDetail.store_id,
              sale_uid: this.orderDetail.sale_uid,
              userType: this.userType,
              act_tag: tag,
              saveData: saveData,
            })
            .then((res) => {
              this.loading = false;
              this.getOrderDetail();
            })
            .catch((error) => {
              this.loading = false;
            });
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },
  },
};
</script>
  <style scoped>
.red {
  color: #cc0000;
}
.btnbox {
  position: fixed;
  z-index: 2;
  left: 20px;
  right: 20px;
  bottom: 10px;
}
.header {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin: 10px auto;
  overflow: hidden;
}
</style>
  