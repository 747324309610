<template>
  <div class="hello">
    <van-sticky>
      <van-nav-bar
        :title="pageTitle"
        :left-text="leftText"
        :right-text="rightText"
        left-arrow
        @click-left="onClickLeft"
      >
        <template #right>
          <!-- <van-tag type="primary" plain @click="sild = !sild">
            <span v-if="sild == true">收起<van-icon name="arrow-up" /></span>
            <span v-else>展开<van-icon name="arrow-down" /></span>
          </van-tag> -->
          <van-icon name="search" size="20" @click="sild = !sild" />
        </template>
      </van-nav-bar>
      <div class="filter">
        <template v-if="sild">
          <div class="type" v-if="tabArr.length > 1">
            门店:
            <van-tag
              v-for="(item, idx) in tabArr"
              round
              type="primary"
              :plain="searchForm.store_id == item.id ? false : true"
              size="large"
              :key="idx"
              style="margin: 0 2px"
              @click="selectStore(item.id)"
              >{{ item.title }}</van-tag
            >
          </div>
          <div class="type">
            时间:
            <van-tag
              round
              type="primary"
              v-for="(item, idx) in dateArr"
              @click="selectDate(item.tag)"
              :plain="searchForm.date_tag == item.tag ? false : true"
              :key="idx"
              size="large"
              style="margin: 0 2px"
              >{{ item.title }}</van-tag
            >
          </div>
          <div class="type">
            类别:
            <van-tag
              round
              type="primary"
              v-for="(item, idx) in typeArr"
              @click="selectType(item.tag)"
              :plain="searchForm.type == item.tag ? false : true"
              :key="idx"
              size="large"
              style="margin: 0 2px"
              >{{ item.title }}</van-tag
            >
          </div>
          <div class="type">
            结算状态:
            <van-tag
              round
              type="primary"
              v-for="(item, idx) in jsArr"
              @click="selectJs(item.tag)"
              :plain="searchForm.js == item.tag ? false : true"
              :key="idx"
              size="large"
              style="margin: 0 2px"
              >{{ item.title }}</van-tag
            >
          </div>
          <div class="type">
            查询方式:
            <van-tag
              round
              type="primary"
              v-for="(item, idx) in showArr"
              @click="selectShow(item.tag)"
              :plain="searchForm.show == item.tag ? false : true"
              :key="idx"
              size="large"
              style="margin: 0 2px"
              >{{ item.title }}</van-tag
            >
          </div>
        </template>
        <div class="type" style="color: #cc0000">
          总金额 : ￥{{ totalAmount }}
        </div>
      </div>
    </van-sticky>

    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        :loading="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="getDataList"
      >
        <template v-if="searchForm.show == 'worker'">
          <div v-for="(item, idx) in dataList" :key="idx">
            <div class="user_list">
              <div class="avatar">
                <van-image
                  style="width: 100%; height: 100%"
                  round
                  :src="item.avatar != '' ? item.avatar : item.user_avatar"
                />
              </div>
              <div class="con">
                <div class="title">
                  {{ item.name }}
                </div>
                <div
                  class="amount_no"
                  @click="jiesuan(item, 0)"
                  v-if="searchForm.js == 'no'"
                >
                  <span>￥{{ item.total }}</span>
                  <div>
                    <van-tag type="warning">去结算</van-tag>
                  </div>
                </div>
                <div class="amount_yes" v-else-if="searchForm.js == 'yes'">
                  <span>￥{{ item.total }}</span>
                </div>
                <div class="amount_yes" v-else>
                  <span style="color: #ff9900">￥{{ item.total }}</span>
                </div>
              </div>
            </div>
          </div>
        </template>

        <template v-else>
          <div v-for="(item, idx) in dataList" :key="idx">
            <div class="user_list">
              <div class="avatar">
                <van-image
                  style="width: 100%; height: 100%"
                  round
                  :src="item.avatar != '' ? item.avatar : item.user_avatar"
                />
              </div>
              <div class="con">
                <div class="title2">
                  <div>
                    {{ item.name }}
                    <van-tag size="small" type="primary">{{
                      item.type_txt
                    }}</van-tag>
                  </div>
                  <div>单号 {{ item.order_sn }}</div>
                  <div>
                    <span v-if="item.remark != item.type_txt">{{
                      item.remark
                    }}</span>
                    <span v-if="item.mg_remark" style="color: #ff9900"
                      >({{ item.mg_remark }})</span
                    >
                  </div>
                </div>
                <div class="amount_no" v-if="item.js_status == 0">
                  <span>￥{{ item.amount }}</span>
                  <div>
                    <van-button
                      type="success"
                      size="mini"
                      @click="changeCommission(item)"
                      >调整</van-button
                    >
                  </div>
                  <div>
                    <van-button
                      type="warning"
                      size="mini"
                      @click="jiesuan(item, item.id)"
                      >去结算</van-button
                    >
                  </div>
                </div>
                <div class="amount_yes" v-else>
                  <span>￥{{ item.amount }}</span>
                </div>
              </div>
            </div>
          </div>
        </template>
      </van-list>
    </van-pull-refresh>

    <!-- 调整提成 -->

    <van-popup
      v-model:show="showPopup"
      position="bottom"
      :style="{ minHeight: '30%' }"
    >
      <div style="padding: 10px 0">
        <div style="text-align: center; height: 40px; line-height: 40px">
          调整提成
        </div>
        <van-cell title="员工" :value="changeData.name" />
        <van-cell title="提成类别" :value="changeData.type_txt" />
        <van-cell title="订单号" :value="changeData.order_sn" />
        <van-cell title="原提成" :value="changeData.amount" />
        <van-field
          v-model="changeData.new_amount"
          type="number"
          label="新提成金额"
          input-align="right"
        />
        <van-field
          label="操作说明"
          type="textarea"
          rows="2"
          v-model="changeData.mg_remark"
          placeholder="请填写操作说明"
          maxlength="100"
          show-word-limit
        />
        <div style="text-align: center; margin-top: 10px">
          <van-button type="primary" @click="changeCommissionDo"
            >保存数据</van-button
          >
        </div>
      </div>
    </van-popup>
  </div>
</template>
  
<script>
import { showToast, showConfirmDialog } from "vant";
export default {
  name: "orderData",
  props: {
    pageTitle: {
      type: String,
      default: "标题",
    },
    leftText: {
      type: String,
      default: "返回",
    },
    rightText: {
      type: String,
      default: "",
    },
    detail: {
      type: Object,
      default: {},
    },
  },
  setup(props, { emit }) {
    function onCallBack(val) {
      emit("callBack", val);
    }
    function clickRightCallBack(val) {
      emit("clickRight", val);
    }
    return { onCallBack, clickRightCallBack };
  },
  data() {
    return {
      nowTab: "all",
      userInfo: false, //用户信息
      loading: false,
      finished: false,
      refreshing: false,
      error: false,
      countInfo: false,
      sild: false,
      dataList: [],
      per_page: 10,
      last_page: 0,
      current_page: 0,
      total: 0,
      totalAmount: "0.00",
      searchForm: {
        tag: "all",
        type: "all",
        show: "worker",
        js: "all",
        store_id: 0,
        date_tag: "today",
        scence: "storeCommission",
      },
      tabArr: [],
      dateArr: [
        {
          title: "今天",
          tag: "today",
        },
        {
          title: "昨天",
          tag: "yesterday",
        },
        {
          title: "本周",
          tag: "week",
        },
        {
          title: "本月",
          tag: "month",
        },
        {
          title: "全部",
          tag: "all",
        },
      ],
      showArr: [
        {
          title: "按员工",
          tag: "worker",
        },
        {
          title: "提成明细",
          tag: "more",
        },
      ],
      jsArr: [
        {
          title: "全部",
          tag: "all",
        },
        {
          title: "已结算",
          tag: "yes",
        },
        {
          title: "待结算",
          tag: "no",
        },
      ],
      typeArr: [
        {
          title: "全部",
          tag: "all",
        },
        {
          title: "销售",
          tag: "sale",
        },
        {
          title: "化妆",
          tag: "makeup",
        },
        {
          title: "摄影",
          tag: "shoot",
        },
        {
          title: "选片",
          tag: "choose",
        },
        {
          title: "修图",
          tag: "ps",
        },
      ],
      showPopup: false,
      changeData: {},
    };
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    window.document.title = this.pageTitle;

    let tmp = false;
    Object.keys(this.userInfo.worker).map((key) => {
      tmp = this.userInfo.worker[key];
      if (tmp.is_store == 1) {
        this.tabArr.push({
          id: tmp.store_id,
          title: tmp.store_title,
        });
      }
    });

    if (!this.tabArr.length) {
      showToast("你没有权限");
      return;
    }
    this.searchForm.store_id = this.tabArr[0].id;

    if (this.tabArr.length < 2) {
      this.tabArr = [];
    }

    this.onRefresh();
  },
  methods: {
    selectType(val) {
      this.searchForm.type = val;
      this.onRefresh();
    },
    selectDate(val) {
      this.searchForm.date_tag = val;
      this.onRefresh();
    },
    selectJs(val) {
      this.searchForm.js = val;
      this.onRefresh();
    },
    selectShow(val) {
      this.searchForm.show = val;
      this.onRefresh();
    },
    selectStore(val) {
      this.searchForm.store_id = val;
      this.onRefresh();
    },
    onRefresh() {
      this.dataList = [];
      this.current_page = 0;
      this.refreshing = true;
      this.getDataList();
    },

    onClickLeft() {
      this.$router.go(-1);
      return;
    },
    changeCommission(item) {
      this.changeData = { ...item };
      this.changeData.mg_remark = "";
      this.changeData.new_amount = 0;
      this.showPopup = true;
    },
    changeCommissionDo() {
      const beforeClose = (action) =>
        new Promise((resolve) => {
          resolve(action);
        });
      let item = this.changeData;
      // 加一个确认
      let msg = `确认将【${item.name}】该笔提成由 ￥${item.amount} 调整为￥${item.new_amount} 吗？`;
      showConfirmDialog({
        title: "提示",
        message: msg,
        beforeClose,
      })
        .then((val) => {
          this.$http
            .postData("/Commission/changeCommission", {
              id: item.id,
              order_id: item.order_id,
              store_id: item.store_id,
              amount: item.amount,
              new_amount: item.new_amount,
              name: item.name,
              mg_remark: item.mg_remark,
              hide_success: false,
            })
            .then((res) => {
              let _this = this;
              this.showPopup = false;
              this.changeData = {};
              setTimeout(function () {
                _this.onRefresh();
              }, 1000);
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    jiesuan(item, id = 0) {
      const beforeClose = (action) =>
        new Promise((resolve) => {
          resolve(action);
        });
      // 加一个确认
      let msg =
        id > 0
          ? `确认结算提成 ￥${item.amount} 给【${item.name}】吗？`
          : `确认结算提成 ￥${item.total} 给【${item.name}】吗？`;
      showConfirmDialog({
        title: "提示",
        message: msg,
        beforeClose,
      })
        .then((val) => {
          this.jiesuanDo(item, id);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    jiesuanDo(item, oid = 0) {
      this.$http
        .postData("/Commission/jiesuanDo", {
          id: oid,
          date_tag: this.searchForm.date_tag,
          store_id: item.store_id,
          worker_id: item.worker_id,
          hide_success: false,
        })
        .then((res) => {
          let _this = this;
          setTimeout(function () {
            _this.onRefresh();
          }, 1000);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onClickItem(val) {
      this.onCallBack(val);
    },
    getDataCount() {
      this.$http
        .postData("/RowList/getDataCount", {
          ...this.searchForm,
          table: "Commission",
        })
        .then((res) => {
          this.countInfo = res;
          console.log(res);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getDataList() {
      if (this.inLoad) {
        return;
      }
      this.inLoad = true;
      this.$http
        .postData("/RowList/getDataCount", {
          ...this.searchForm,
          table: "Commission",
          page_size: this.per_page,
          page: this.current_page + 1,
          hide_success: true,
        })
        .then((res) => {
          this.inLoad = false;
          this.loading = false;
          this.refreshing = false;
          res.data.map((item) => {
            this.dataList.push(item);
          });
          this.current_page = res.current_page;
          this.total = res.total;
          if (res.current_page == 1) {
            this.totalAmount = res.total_amount;
          }
          if (res.last_page == res.current_page || res.last_page == 0) {
            this.finished = true;
          } else {
            this.finished = false;
          }
        })
        .catch((error) => {
          this.inLoad = false;
          this.loading = false;
          this.finished = true;
          this.refreshing = false;
        });
    },
  },
};
</script>
  <style scoped>
.filter {
  padding: 10px 0;
  background-color: #ffffff;
  font-size: 12px;
  box-shadow: 0 0px 4px 0 rgba(0, 0, 0, 0.3);
  margin-bottom: 10px;
}
.filter .type {
  padding: 6px 16px;
}
.user_list {
  position: relative;
  line-height: 50px;
  font-size: 12px;
  padding: 10px;
  background-color: #ffffff;
  box-shadow: 0 0px 1px 0 rgba(0, 0, 0, 0.3);
}
.user_list .con {
  padding-left: 60px;
  display: flex;
  justify-content: space-between;
}
.user_list .avatar {
  position: absolute;
  z-index: 1;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  left: 10px;
  top: 10px;
}
.user_list .con .title2 {
  line-height: 25px;
  color: #999;
}
.user_list .con .amount_no {
  text-align: right;
  color: #cc0000;
  line-height: 25px;
}
.user_list .con .amount_yes {
  text-align: right;
  color: #67c23a;
}
</style>
  