<template>
  <div class="hello" style="background-color: #f3f3f3; min-height: 100vh">
    <van-nav-bar
      :title="pageTitle"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
      @click-right="onClickRight"
    >
      <template #right>
        <van-icon name="search" size="18" />
      </template>
    </van-nav-bar>

    <van-tabs
      v-model:active="searchForm.tag"
      @click-tab="onClickTab"
      sticky
      class="countBox"
    >
      <van-tab
        :title="item.title"
        :name="item.tag"
        :key="idx"
        v-for="(item, idx) in tabArr"
      ></van-tab>
    </van-tabs>
    <!-- 数据列表 -->
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        :loading="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="getList"
      >
        <van-cell
          v-for="(item, idx) in dataList"
          :key="idx"
          @click="onClickItem(item)"
          v-if="
            searchForm.actName == 'store' ||
            searchForm.actName == 'sale' ||
            searchForm.actName == 'user' ||
            searchForm.actName == 'help' ||
            searchForm.actName == 'kefu'
          "
        >
          <template #title>
            <van-tag type="default">{{ item.store_title }}</van-tag>
            {{ item.order_sn }}
          </template>
          <template #value>
            <van-tag type="default" v-if="tag1.includes(item.status)">{{
              item.status_txt
            }}</van-tag>
            <van-tag type="primary" v-if="tag2.includes(item.status)">{{
              item.status_txt
            }}</van-tag>
            <van-tag type="warning" v-if="tag3.includes(item.status)">{{
              item.status_txt
            }}</van-tag>
            <van-tag type="success" v-if="tag4.includes(item.status)">{{
              item.status_txt
            }}</van-tag>
            <van-tag type="danger" v-if="tag5.includes(item.status)">{{
              item.status_txt
            }}</van-tag>
          </template>
          <template #label>
            <div style="line-height: 20px; width: 100%">
              <div>
                保底 <span style="color: #cc0000">{{ item.pic_num }}</span
                >张，精修 <span style="color: #cc0000">{{ item.ps_num }}</span
                >张
              </div>
              <div>
                金额 <span style="color: #cc0000">￥{{ item.total }}</span>
              </div>
              <div>时间 {{ item.create_time }}</div>
            </div>
          </template>
        </van-cell>

        <!-- 子订单 -->
        <template
          v-if="
            searchForm.actName == 'makeup' ||
            searchForm.actName == 'shoot' ||
            searchForm.actName == 'choose'
          "
        >
          <div class="child_item" v-for="(item, idx) in dataList" :key="idx">
            <div class="title">
              <span>工单号 {{ item.order_sn }}</span>
              <span class="tag">
                <van-tag type="default" v-if="item.status == 0">{{
                  item.status_txt
                }}</van-tag>
                <van-tag type="primary" v-if="item.status == 1">{{
                  item.status_txt
                }}</van-tag>
                <van-tag type="success" v-if="item.status == 2">{{
                  item.status_txt
                }}</van-tag>
              </span>
            </div>
            <div class="con">
              <div>原订单号：{{ item.parent_sn }}</div>
              <div>
                客户：{{ item.clients.name ? item.clients.name : "-" }}
                <template v-if="item.clients.mobile">
                  <a
                    style="color: #cc0000"
                    :href="'tel:' + item.clients.mobile"
                    >{{ item.clients.mobile }}</a
                  >
                </template>
              </div>
              <div>
                <span>
                  服装
                  <span style="color: #cc0000"
                    >{{ item.clients.clothes }}套</span
                  >
                </span>
                <span style="margin: 0 6px">
                  底片
                  <span style="color: #cc0000"
                    >{{ item.clients.pic_num }}张</span
                  >
                </span>
                <span>
                  精修
                  <span style="color: #cc0000"
                    >{{ item.clients.ps_num }}张</span
                  >
                </span>
              </div>
              <!-- <div>时间：{{ item.create_time }}</div> -->
            </div>
            <div class="btns">
              <van-button
                size="mini"
                @click="doChild(item)"
                v-if="item.status == 0"
                type="primary"
              >
                开 始
              </van-button>
              <van-button
                size="mini"
                @click="doChild(item)"
                v-if="item.status == 1"
                type="warning"
              >
                结 束
              </van-button>
            </div>
          </div>
        </template>
      </van-list>
    </van-pull-refresh>
    <!-- 搜索 -->
    <van-popup
      v-model:show="showPopup"
      round
      position="bottom"
      :style="{ minHeight: '30%' }"
    >
      <div style="margin-top: 16px">
        <van-cell-group inset label-width="auto">
          <van-field
            v-model="searchForm.keyword"
            label="关键词"
            placeholder="输入关键词查询"
          />
        </van-cell-group>
        <div style="margin: 16px">
          <van-button round block type="primary" @click="toSearch"
            >查询</van-button
          >
        </div>
      </div>
    </van-popup>

    <!-- 选片+上传收款凭证 -->
    <van-popup
      v-model:show="showChoosePopup"
      round
      position="bottom"
      :style="{ minHeight: '30%', maxHeight: '60%' }"
    >
      <div style="padding: 20px 0">
        <van-cell title="添加精修">
          <template #value>
            <van-stepper
              v-model="chooseData.add_num"
              @change="changeAddNum"
              :min="0"
              :max="9999"
            />
            张
          </template>
        </van-cell>
        <van-field
          label="选片费用"
          type="digit"
          v-model="chooseData.total"
          maxlength="11"
          input-align="right"
          placeholder="可手动修改"
        />
        <van-cell title="收款截图">
          <template #value>
            <van-uploader
              v-model="fileList"
              :preview-image="true"
              :max-count="1"
              :after-read="afterRead"
            ></van-uploader>
          </template>
        </van-cell>
        <div style="text-align: center; margin-top: 10px">
          <van-button type="primary" @click="sendChooseData"
            >结束选片</van-button
          >
        </div>
      </div>
    </van-popup>
  </div>
</template>
  
<script>
import { showConfirmDialog, showToast } from "vant";
export default {
  name: "workSpace",
  data() {
    return {
      pageTitle: "工作台",
      showPopup: false,
      loading: false,
      finished: false,
      refreshing: false,
      showChoosePopup: false,
      chooseData: {
        oid: 0,
        store_id: 0,
        add_num: 0,
        total: 0,
        pay_pic: "",
        per_price: 0,
      },
      fileList: [],
      dataList: [],
      per_page: 20,
      last_page: 0,
      current_page: 0,
      total: 0,
      table: "Order",
      tag1: [11, 9],
      tag2: [10, 15, 20, 30, 40, 50, 22, 32, 42],
      tag3: [12, 21, 31, 41],
      tag4: [60, 61],
      tag5: [13, 14],
      searchForm: {
        tag: "all",
        scence: "orderList",
        actName: false,
        keyword: "",
      },
      tabArr: [
        {
          title: "全部",
          tag: "all",
          value: "",
        },
        {
          title: "待处理",
          tag: "wait",
          value: "",
        },
        {
          title: "已处理",
          tag: "done",
          value: "",
        },
      ],
    };
  },
  components: {},
  mounted() {
    this.searchForm.actName =
      this.$route.params.actName != null && this.$route.params.actName
        ? this.$route.params.actName
        : false;
    let mtit = "";
    if (this.searchForm.actName == "store") {
      mtit = "店长";
    } else if (this.searchForm.actName == "sale") {
      mtit = "销售";
    } else if (this.searchForm.actName == "makeup") {
      mtit = "化妆师";
      this.table = "OrderChild";
      this.searchForm.scence = "orderChild";
    } else if (this.searchForm.actName == "shoot") {
      mtit = "摄影";
      this.table = "OrderChild";
      this.searchForm.scence = "orderChild";
    } else if (this.searchForm.actName == "choose") {
      mtit = "选片";
      this.table = "OrderChild";
      this.searchForm.scence = "orderChild";
    } else if (this.searchForm.actName == "help") {
      mtit = "助理";
    } else if (this.searchForm.actName == "kefu") {
      mtit = "客服";
    }

    if (
      this.searchForm.actName == "makeup" ||
      this.searchForm.actName == "shoot" ||
      this.searchForm.actName == "choose" ||
      this.searchForm.actName == "ps"
    ) {
      this.tabArr = [
        {
          title: "全部",
          tag: "all",
          value: "",
        },
        {
          title: "待处理",
          tag: "wait",
          value: "",
        },
        {
          title: "进行中",
          tag: "doing",
          value: "",
        },
        {
          title: "已完成",
          tag: "done",
          value: "",
        },
      ];
    } else if (this.searchForm.actName == "user") {
      this.tabArr = [
        {
          title: "全部",
          tag: "all",
          value: "",
        },
        {
          title: "处理中",
          tag: "wait",
          value: "",
        },
        {
          title: "进行中",
          tag: "doing",
          value: "",
        },
        {
          title: "已完成",
          tag: "done",
          value: "",
        },
      ];
    }
    this.pageTitle += "【" + mtit + "】";

    if (this.searchForm.actName == "user") {
      this.pageTitle = "我的订单";
    }
    window.document.title = this.pageTitle;
  },
  methods: {
    afterRead(file, name) {
      let params = new FormData(); //创建form对象
      params.append("files", file.file);
      this.$http
        .uploadPic("/Upload/uploadFile", params)
        .then((res) => {
          this.chooseData.pay_pic = res.url;
        })
        .catch((error) => {});
    },
    changeAddNum(val) {
      // 调整精修张数
      this.chooseData.total = val * this.chooseData.per_price;
    },
    sendChooseData() {
      if (this.chooseData.add_num > 0 && !this.chooseData.pay_pic) {
        showToast("请上传收款截图");
        return;
      }
      const beforeClose = (action) =>
        new Promise((resolve) => {
          resolve(action);
        });

      // 加一个确认
      showConfirmDialog({
        title: "提示",
        message: `确认提交吗？`,
        beforeClose,
      })
        .then((val) => {
          this.$http
            .postData("/OrderChild/doChild", {
              oid: this.chooseData.oid,
              store_id: this.chooseData.store_id,
              saveData: {
                add_num: this.chooseData.add_num,
                total: this.chooseData.total,
                pay_pic: this.chooseData.pay_pic,
              },
            })
            .then((res) => {
              this.loading = false;
              this.showChoosePopup = false;
              this.onRefresh();
            })
            .catch((error) => {
              this.loading = false;
            });
          this.loading = false;
          return;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },
    doChild(item) {
      if (item.type == "choose" && item.status == 1) {
        // 选片环节，结束需要提交数据
        this.chooseData = {
          oid: item.id,
          store_id: item.store_id,
          add_num: 0,
          total: 0,
          pay_pic: "",
          per_price: item.per_price,
        };
        this.fileList = [];
        this.showChoosePopup = true;
        return;
      }
      const beforeClose = (action) =>
        new Promise((resolve) => {
          resolve(action);
        });
      let msg = item.status == 0 ? "确认开始处理吗？" : "确认结束吗？";
      // 加一个确认
      showConfirmDialog({
        title: "提示",
        message: msg,
        beforeClose,
      })
        .then((val) => {
          this.$http
            .postData("/OrderChild/doChild", {
              oid: item.id,
              store_id: item.store_id,
            })
            .then((res) => {
              this.onRefresh();
            })
            .catch((error) => {});
        })
        .catch((e) => {
          console.log(e);
        });
    },
    onClickTab(val) {
      this.searchForm.tag = val.name;
      if (this.act == "share" || this.act == "user") {
        this.searchForm.tag = val.name;
      } else if (this.act == "balance") {
        this.searchForm.tag = val.name;
      }
      this.onRefresh();
    },
    toSearch() {
      this.dataList = [];
      this.current_page = 0;
      this.refreshing = true;
      this.showPopup = false;
      this.getList();
    },
    onClickLeft() {
      this.$router.push({ name: "Home" });
    },
    onClickRight() {
      this.showPopup = true;
    },
    onRefresh() {
      this.dataList = [];
      this.current_page = 0;
      this.refreshing = true;
      this.getList();
    },
    onClickItem(val) {
      this.$router.push({
        name: "orderDetail",
        params: {
          userType: this.searchForm.actName,
          oid: val.id,
        },
      });
    },
    getList() {
      if (this.inLoad) {
        return;
      }
      this.inLoad = true;
      this.$http
        .postData("/RowList/getList", {
          table: this.table,
          pageInfo: {
            page_size: this.per_page,
          },
          page: this.current_page + 1,
          search: { ...this.searchForm },
          hide_success: true,
        })
        .then((res) => {
          this.inLoad = false;
          res.data.map((item) => {
            this.dataList.push(item);
          });
          this.current_page = res.current_page;
          this.total = res.total;

          if (res.last_page == res.current_page || res.last_page == 0) {
            this.loading = false;
            this.finished = true;
          }
          this.refreshing = false;
        })
        .catch((error) => {
          this.inLoad = false;
          this.refreshing = false;
          this.finished = true;
          console.log(error);
        });
    },
  },
};
</script>
  <style scoped>
.child_item {
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 3px 1px 0px rgba(0, 0, 0, 0.1);
  position: relative;
  margin: 10px 16px;
  padding: 10px;
}

.child_item .title {
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  color: #333333;
  box-shadow: 0 1px 0px 0 rgba(0, 0, 0, 0.1);
}
.child_item .title .tag {
  margin-left: 10px;
}

.child_item .con {
  font-size: 12px;
  line-height: 25px;
  color: #666666;
}

.child_item .btns {
  position: absolute;
  z-index: 1;
  right: 10px;
  top: 10px;
}

.item_list {
  margin: 10px;
  background-color: #ffffff;
  font-size: 14px;
  border-radius: 10px;
  box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.3);
  position: relative;
}
.item_list .head {
  display: flex;
  justify-content: space-between;
  padding-top: 4px;
  padding-bottom: 4px;
}
.item_list .head .title {
  padding-left: 50px;
  font-size: 14px;
  color: #000000;
  line-height: 30px;
  height: 30px;
}
.item_list .head .times {
  padding-left: 50px;
  font-size: 12px;
  line-height: 20px;
  height: 20px;
  color: #666666;
}
.item_list .avatar {
  position: absolute;
  left: 15px;
  top: 10px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.item_list .avatar img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
</style>
  