<template>
  <div class="hello" v-if="ready">
    <template v-if="userInfo">
      <van-row class="tops">
        <van-col span="6">
          <van-image
            round
            width="4rem"
            height="4rem"
            style="margin: 3rem 1rem; display: block"
            :src="userInfo.avatar"
            @click="logout"
          />
        </van-col>
        <van-col span="18">
          <div class="infobox">
            <div>
              <div class="nickname">{{ userInfo.nickname }}</div>
              <div class="worker_status" v-if="userInfo.worker"></div>
              <div v-if="userInfo.worker_ids.length">
                <van-tag
                  v-if="userInfo.permission.store_ids.length"
                  style="margin: 0 3px"
                  type="success"
                  >店长</van-tag
                >
                <van-tag
                  v-if="userInfo.permission.makeup_ids.length"
                  style="
                    margin: 0 3px;
                    background-color: rgba(242, 76, 76, 0.8);
                  "
                  type="danger"
                  >化妆师</van-tag
                >

                <van-tag
                  v-if="userInfo.permission.shoot_ids.length"
                  style="margin: 0 3px"
                  type="warning"
                  >摄影师</van-tag
                >
                <van-tag
                  v-if="userInfo.permission.is_ps"
                  style="margin: 0 3px; background-color: rgba(200, 90, 220, 1)"
                  type="danger"
                  >后期</van-tag
                >
                <van-tag
                  v-if="userInfo.permission.choose_ids.length"
                  style="margin: 0 3px; background-color: rgba(200, 90, 220, 1)"
                  type="danger"
                  >选片</van-tag
                >
                <van-tag
                  v-if="userInfo.permission.sale_ids.length"
                  style="margin: 0 3px; background-color: rgba(218, 120, 45, 1)"
                  type="success"
                  >销售</van-tag
                >
                <van-tag
                  v-if="userInfo.permission.help_ids.length"
                  style="margin: 0 3px; background-color: #9e008e"
                  type="success"
                  >助理</van-tag
                >
                <van-tag
                  v-if="userInfo.permission.kefu_ids.length"
                  style="margin: 0 3px; background-color: #33a753"
                  type="success"
                  >客服</van-tag
                >
              </div>
              <div v-else>
                <van-tag style="margin: 0 3px" type="success">用户</van-tag>
              </div>
            </div>
          </div>
        </van-col>
      </van-row>
      <!-- 操作菜单 -->
      <div>
        <van-cell-group inset style="color: #33a753">
          <van-cell
            title="工作状态"
            icon="todo-list-o"
            is-link
            @click="changeOnline()"
            v-if="userInfo.worker"
          >
            <template #value>
              <van-tag type="success" size="small" v-if="userInfo.online"
                >工作中</van-tag
              >
              <van-tag type="default" size="small" v-else>休息中</van-tag>
            </template>
          </van-cell>
          <van-cell
            title="工作台"
            icon="underway-o"
            is-link
            v-if="
              userInfo.permission.choose_ids.length ||
              userInfo.permission.sale_ids.length ||
              userInfo.permission.shoot_ids.length ||
              userInfo.permission.makeup_ids.length ||
              userInfo.permission.store_ids.length ||
              userInfo.permission.kefu_ids.length ||
              userInfo.permission.help_ids.length
            "
            @click="goWorkSpace"
          />
          <van-cell
            title="查看数据"
            icon="bill-o"
            is-link
            v-if="userInfo.permission.store_ids.length"
            @click="viewData"
          />
          <van-cell
            title="我的客户"
            icon="friends"
            is-link
            v-if="userInfo.permission.sale_ids.length"
            to="/user/getList"
          />
          <van-cell
            title="二维码"
            icon="qr"
            is-link
            v-if="userInfo.permission.sale_ids.length"
            @click="showQrcodeOne"
          />
          <van-cell
            title="我的提成"
            icon="bill-o"
            is-link
            v-if="
              userInfo.permission.store_ids.length ||
              userInfo.permission.sale_ids.length ||
              userInfo.permission.makeup_ids.length
            "
            to="/commission/getList"
          />
          <van-cell
            title="我的订单"
            icon="orders-o"
            is-link
            to="/workSpace/user"
          />
        </van-cell-group>
      </div>
    </template>

    <van-popup
      v-model:show="showPopup"
      round
      position="bottom"
      :style="{ minHeight: '30%' }"
    >
      <template #default>
        <div class="tools">
          <div class="title van-hairline--bottom">选择身份</div>
          <van-grid :column-num="3" :gutter="10" square>
            <van-grid-item
              v-for="(item, idx) in options"
              :key="idx"
              @click="selectAct(item)"
            >
              <div class="item">
                <van-icon
                  class="icon"
                  :style="{ color: item.color }"
                  :name="item.icon"
                />
                <div>{{ item.name }}</div>
              </div>
            </van-grid-item>
          </van-grid>
        </div>
      </template>
    </van-popup>

    <van-popup
      v-model:show="showPopup2"
      round
      position="bottom"
      :style="{ minHeight: '30%' }"
    >
      <template #default>
        <div class="tools">
          <div class="title van-hairline--bottom">选择数据类型</div>
          <van-grid :column-num="3" :gutter="10" square>
            <van-grid-item
              v-for="(item, idx) in options"
              :key="idx"
              @click="selectDataType(item)"
            >
              <div class="item">
                <van-icon
                  class="icon"
                  :style="{ color: item.color }"
                  :name="item.icon"
                />
                <div>{{ item.name }}</div>
              </div>
            </van-grid-item>
          </van-grid>
        </div>
      </template>
    </van-popup>

    <van-popup
      v-model:show="showFromPopup"
      position="bottom"
      :style="{ minHeight: '30%' }"
    >
      <van-picker title="选择门店" :columns="fromArr" @confirm="selectWorker" />
    </van-popup>

    <QrcodeNew
      v-if="showPosterBox"
      @onClose="showPosterBox = false"
      :info="posterInfo"
    />
  </div>
  <div
    v-else
    style="
      text-align: center;
      line-height: 60px;
      height: 60px;
      margin-top: 20px;
      font-size: 14px;
      color: #999;
    "
  >
    请稍后
  </div>
</template>
  
<script>
import { showConfirmDialog, showToast } from "vant";
import QrcodeNew from "@/components/QrcodeNew.vue";

export default {
  name: "Home",
  components: {
    QrcodeNew,
  },
  data() {
    return {
      msg: "个人中心",
      showPosterBox: false,
      posterInfo: {},
      userInfo: false,
      ready: false,
      showPopup: false,
      showFromPopup: false,
      options: [],
      fromArr: [],
      worker: false,
      inviteCode: false,
      showPopup2: false,
    };
  },
  mounted() {
    this.inviteCode =
      this.$route.query.inviteCode != null && this.$route.query.inviteCode
        ? this.$route.query.inviteCode
        : false;
    this.getUserInfo();
  },
  methods: {
    selectWorker(val) {
      this.showFromPopup = false;
      let tmp = val.selectedOptions[0];
      this.showQrcode(tmp);
    },
    showQrcodeOne() {
      this.showFromPopup = true;
    },
    changeOnline() {
      showConfirmDialog({
        message: "确认更改工作状态吗?",
      })
        .then(() => {
          this.$http
            .postData("/User/changeOnline", {})
            .then((res) => {
              this.getUserInfo();
            })
            .catch((error) => {});
        })
        .catch(() => {
          console.log("cancel");
        });
    },
    showQrcode(item) {
      let codeUrl = this.$domain + "?inviteCode=" + item.id;
      this.posterInfo = {
        nickname: item.show_name,
        avatar: item.avatar ? item.avatar : this.userInfo.avatar,
        title: item.store_title,
        stitle: "工号:" + item.num + " 您的业务经理",
        tips: "- 长按识别 开启优质旅拍 -",
        codeUrl: codeUrl,
      };
      this.showPosterBox = true;
    },
    goWorkSpace() {
      if (!this.userInfo.online) {
        showToast("请先切换到工作状态");
        return;
      }
      let opt = [];
      if (this.userInfo.permission.store_ids.length) {
        opt.push({
          name: "店长",
          icon: "shop-o",
          act: "store",
          color: "#23cc77",
        });
      }
      if (this.userInfo.permission.sale_ids.length) {
        opt.push({
          name: "销售",
          icon: "bag-o",
          act: "sale",
          color: "#0a96d1",
        });
      }
      if (this.userInfo.permission.makeup_ids.length) {
        opt.push({
          name: "化妆师",
          icon: "smile-o",
          act: "makeup",
          color: "#b951dc",
        });
      }
      if (this.userInfo.permission.shoot_ids.length) {
        opt.push({
          name: "摄影师",
          icon: "video",
          act: "shoot",
          color: "#c28fe0",
        });
      }
      if (this.userInfo.permission.choose_ids.length) {
        opt.push({
          name: "选片",
          icon: "tv-o",
          act: "choose",
          color: "#0b31e2",
        });
      }
      if (this.userInfo.permission.help_ids.length) {
        opt.push({
          name: "助理",
          icon: "manager-o",
          act: "help",
          color: "#9e008e",
        });
      }
      if (this.userInfo.permission.kefu_ids.length) {
        opt.push({
          name: "客服",
          icon: "service-o",
          act: "kefu",
          color: "#23cc77",
        });
      }
      if (!opt || !opt.length) {
        return;
      }
      if (opt.length == 1) {
        this.$router.push({
          name: "workSpace",
          params: {
            actName: opt[0].act,
          },
        });
      } else {
        this.options = opt;
        this.showPopup = true;
      }
    },
    selectAct(opt) {
      this.$router.push({
        name: "workSpace",
        params: {
          actName: opt.act,
        },
      });
    },
    viewData() {
      if (!this.userInfo.permission.store_ids.length) {
        showToast("没有权限");
        return;
      }
      let opt = [
        {
          name: "营业数据",
          icon: "chart-trending-o",
          act: "store",
          color: "#23cc77",
        },
        {
          name: "提成数据",
          icon: "balance-o",
          act: "commission",
          color: "#23cc77",
        },
      ];
      this.options = opt;
      this.showPopup2 = true;
    },
    selectDataType(opt) {
      this.$router.push({
        name: "viewData",
        params: {
          dataType: opt.act,
        },
      });
    },
    getUserInfo() {
      this.$http
        .postData("/User/getUserInfo", {
          inviteCode: this.inviteCode,
        })
        .then((res) => {
          localStorage.setItem("userInfo", JSON.stringify(res));
          this.userInfo = res;
          this.fromArr = [];
          let tmp = false;
          Object.keys(res.worker).map((key) => {
            tmp = this.userInfo.worker[key];
            if (tmp.is_sale == 1) {
              this.fromArr.push({
                id: tmp.id,
                value: tmp.id,
                text: tmp.store_title,
                store_id: tmp.store_id,
                name: tmp.name,
                num: tmp.num,
                show_name: tmp.show_name,
                mobile: tmp.mobile,
                avatar: tmp.avatar,
                store_title: tmp.store_title,
              });
            }
          });
          this.ready = true;
        })
        .catch((error) => {
          this.ready = true;
          console.log(error);
        });
    },
    logout() {
      showConfirmDialog({
        message: "确认要退出登陆吗?",
      })
        .then(() => {
          localStorage.removeItem("userInfo");
          this.userInfo = false;
          localStorage.setItem("redirect_url", location.href);
          window.location.href = "/";
        })
        .catch(() => {
          console.log("cancel");
        });
    },
  },
};
</script>
  <style scoped>
.tops {
  background-color: #1989fa;
  /*  #00c368 */
}
.infobox {
  display: flex;
  align-items: center;
  color: #ffffff;
  font-size: 1rem;
  line-height: 1.5rem;
  height: 100%;
}
.tops .nickname {
  font-size: 1.5rem;
  line-height: 2.5rem;
}
.tops .my-kefu {
  font-size: 12px;
  line-height: 30px;
}

.tools {
  padding: 10px;
}
.tools .title {
  text-align: center;
  font-size: 16px;
  color: #666666;
  padding-bottom: 14px;
  margin-bottom: 10px;
}
.tools .item {
  font-size: 12px;
  text-align: center;
  line-height: 24px;
}
.tools .icon {
  font-size: 30px;
}
</style>
  