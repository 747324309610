<template>
  <div class="hello">
    <van-sticky>
      <van-nav-bar
        :title="pageTitle"
        :left-text="leftText"
        :right-text="rightText"
        left-arrow
        @click-left="onClickLeft"
        @click-right="onClickRight"
      >
        <!-- <template #right>
          <van-icon name="chart-trending-o" size="18" />
        </template> -->
      </van-nav-bar>
      <div class="filter">
        <div class="type" v-if="tabArr.length > 1">
          门店:
          <van-tag
            v-for="(item, idx) in tabArr"
            round
            type="primary"
            :plain="searchForm.store_id == item.id ? false : true"
            size="large"
            :key="idx"
            style="margin: 0 2px"
            @click="selectStore(item.id)"
            >{{ item.title }}</van-tag
          >
        </div>
        <div class="type">
          时间:
          <van-tag
            round
            type="primary"
            v-for="(item, idx) in dateArr"
            @click="selectDate(idx)"
            :plain="searchForm.date_tag == item.tag ? false : true"
            :key="idx"
            size="large"
            style="margin: 0 2px"
            >{{ item.title }}</van-tag
          >
        </div>
      </div>
    </van-sticky>

    <div style="padding-top: 20px">
      <van-cell-group
        inset
        label-width="auto"
        v-if="countInfo && countInfo.length"
      >
        <van-cell
          v-for="(item, idx) in countInfo"
          input-align="right"
          :key="idx"
          :value="item.value"
          :title="item.title"
        >
          <template #button v-if="item.unit">{{ item.unit }}</template>
        </van-cell>
      </van-cell-group>
      <div
        v-else
        style="
          height: 40px;
          text-align: center;
          color: #666666;
          line-height: 40px;
        "
      >
        暂无数据
      </div>
    </div>
  </div>
</template>
  
<script>
import { showToast, showNotify } from "vant";
export default {
  name: "orderData",
  props: {
    pageTitle: {
      type: String,
      default: "标题",
    },
    leftText: {
      type: String,
      default: "返回",
    },
    rightText: {
      type: String,
      default: "",
    },
    detail: {
      type: Object,
      default: {},
    },
  },
  setup(props, { emit }) {
    function onCallBack(val) {
      emit("callBack", val);
    }
    function clickRightCallBack(val) {
      emit("clickRight", val);
    }
    return { onCallBack, clickRightCallBack };
  },
  data() {
    return {
      nowTab: "all",
      userInfo: false, //用户信息
      loading: false,
      finished: false,
      refreshing: false,
      error: false,
      countInfo: false,
      dataList: [],
      per_page: 20,
      last_page: 0,
      current_page: 0,
      total: 0,
      searchForm: {
        tag: "all",
        store_id: 0,
        date_tag: "today",
        scence: "storeMoney",
      },
      tabArr: [],
      dateArr: [
        {
          title: "今天",
          tag: "today",
        },
        {
          title: "昨天",
          tag: "yesterday",
        },
        {
          title: "本周",
          tag: "week",
        },
        {
          title: "本月",
          tag: "month",
        },
        {
          title: "全部",
          tag: "all",
        },
      ],
      showPopup: false,
    };
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    window.document.title = this.pageTitle;

    let tmp = false;
    Object.keys(this.userInfo.worker).map((key) => {
      tmp = this.userInfo.worker[key];
      if (tmp.is_store == 1) {
        this.tabArr.push({
          id: tmp.store_id,
          title: tmp.store_title,
        });
      }
    });

    if (!this.tabArr.length) {
      showToast("你没有权限");
      return;
    }
    this.searchForm.store_id = this.tabArr[0].id;

    if (this.tabArr.length < 2) {
      this.tabArr = [];
    }

    this.getDataCount();
  },
  methods: {
    selectDate(idx) {
      this.searchForm.date_tag = this.dateArr[idx].tag;
      this.onRefresh();
    },
    onRefresh() {
      this.dataList = [];
      this.current_page = 0;
      this.refreshing = true;
      this.getDataCount();
    },
    selectStore(val) {
      this.searchForm.store_id = val;
      this.onRefresh();
    },
    onClickLeft() {
      this.$router.go(-1);
      return;
    },
    onClickRight() {
      this.getDataCount();
    },
    onClickItem(val) {
      this.onCallBack(val);
    },
    getDataCount() {
      this.$http
        .postData("/RowList/getDataCount", {
          ...this.searchForm,
          table: "Order",
        })
        .then((res) => {
          this.countInfo = res;
          console.log(res);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getDataList() {
      if (this.inLoad) {
        return;
      }
      this.inLoad = true;
      this.$http
        .postData("/RowList/getDataCount", {
          table: "Store",
          search: this.searchForm,
          pageInfo: {
            page_size: this.per_page,
          },
          page: this.current_page + 1,
          hide_success: true,
        })
        .then((res) => {
          this.inLoad = false;
          this.loading = false;
          this.refreshing = false;
          res.data.map((item) => {
            this.dataList.push(item);
          });
          this.current_page = res.current_page;
          this.total = res.total;

          if (res.last_page == res.current_page || res.last_page == 0) {
            this.finished = true;
          }
        })
        .catch((error) => {
          this.inLoad = false;
          this.loading = false;
          this.finished = true;
          this.refreshing = false;
        });
    },
  },
};
</script>
  <style scoped>
.filter {
  padding: 10px 0;
  background-color: #ffffff;
  font-size: 12px;
  box-shadow: 0 0px 4px 0 rgba(0, 0, 0, 0.3);
}
.filter .type {
  padding: 6px 16px;
}
.user_list .head {
  padding-left: 60px;
  display: flex;
  justify-content: space-between;
  padding-top: 4px;
  padding-bottom: 4px;
}
.user_list .head .title {
  font-size: 14px;
  color: #000000;
  line-height: 30px;
  height: 30px;
}
.user_list .head .times {
  font-size: 12px;
  line-height: 20px;
  height: 20px;
  color: #666666;
}
.user_list .head .mark {
  font-size: 12px;
  line-height: 20px;
  height: 20px;
  color: #666666;
}
.user_list .avatar {
  position: absolute;
  left: 15px;
  top: 10px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.user_list .avatar img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.user_list .infos {
  line-height: 50px;
  padding-right: 10px;
  color: #ff0000;
}
</style>
  