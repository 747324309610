// 海报图
export function drawPoster(data) {
    return new Promise(function(resolve) {
      switch (data.type) {
        case 1:
          resolve(poster1(data))
          break
        case 2:
            resolve(poster2(data))
            break
        case 3:
            resolve(poster3(data))
            break
        default:
          resolve(poster1(data))
      }
    })
  }
  const bWidth = `${document.documentElement.clientWidth}px`
  const bHeight = `${document.documentElement.clientWidth * 1.8}px`

  // demo 微信分享图
  function poster1(data) {
    return {
        width: bWidth,
        height: bHeight,
        borderRadius:'10px',
        background: '#ffffff',
        views: [
        {
            type: "image",
            url: "/bg/post1.png",
            css: {
              width: bWidth,
              height: bHeight,
              borderRadius:'10px',
              top: "0px",
              bottom:"0px"
            },
          },
          {
            type: "image",
            url: data.info.avatar,
            css: {
              width: `80px`,
              height: `80px`,
              margin: "0 auto",
              top: `100px`,
              left: `${(document.documentElement.clientWidth-80) / 2}px`,
              borderRadius:'100%',
              borderColor:'rgba(255,255,255,0.8)',
              borderWidth:'3px',
            },
          },
          {
            type: "text",
            text: data.info.nickname,
            css: [
              {
                width:bWidth,
                textAlign:"center",
                top: `200px`,
                left: `0px`,
                color: "#0b7387",
                fontSize: "20px",
              },
            ],
          },
          {
            type: "text",
            text: data.info.stitle,
            css: [
              {
                width:bWidth,
                textAlign:"center",
                top: `230px`,
                left: `0px`,
                color: "#0b7387",
                fontSize: "17px",
              },
            ],
          },
          {
            type: "text",
            text: data.info.times,
            css: [
              {
                width:bWidth,
                textAlign:"right",
                bottom: `40px`,
                right: `20px`,
                color: "#0b7387",
                fontSize: "17px",
              },
            ],
          },
          {
            type: "qrcode",
            content: data.info.codeUrl,
            css: {
              top: `340px`,
              left: `${(document.documentElement.clientWidth-120) / 2}px`,
              color: data.info.has_auth?'#ff0000':"#32acd2",
              borderWidth:"4px",
              borderColor:"#ffffff",
              background:"#FFFFFF",
              width: `120px`,
              height: `120px`,
            },
          },{
            type: "text",
            text: '门店：'+data.info.title,
            css: [
              {
                width:bWidth,
                textAlign:"right",
                bottom: `70px`,
                right: `20px`,
                color: "#0b7387",
                fontSize: "16px",
              },
            ],
          },
          {
            type: "text",
            text: data.info.tips,
            css: [
              {
                width:bWidth,
                textAlign:"center",
                top: `480px`,
                left: `0px`,
                color: "#167390",
                fontSize: "17px",
              },
            ],
          },
        ],
      }
  }

  function poster2(data) {
    return {
        width: bWidth,
        height: bHeight,
        borderRadius:'10px',
        background: '#ffffff',
        views: [
        {
            type: "image",
            url: "/bg/post2.png",
            css: {
              width: bWidth,
              height: bHeight,
              borderRadius:'10px',
              top: "0px",
              bottom:"0px"
            },
          },
          {
            type: "image",
            url: data.info.avatar,
            css: {
              width: `80px`,
              height: `80px`,
              margin: "0 auto",
              top: `100px`,
              left: `${(document.documentElement.clientWidth-80) / 2}px`,
              borderRadius:'100%',
              borderColor:'rgba(255,255,255,0.8)',
              borderWidth:'3px',
            },
          },
          {
            type: "text",
            text: data.info.nickname,
            css: [
              {
                width:bWidth,
                textAlign:"center",
                top: `210px`,
                left: `0px`,
                color: "#1c6764",
                fontSize: "20px",
              },
            ],
          },
          {
            type: "text",
            text: data.info.stitle,
            css: [
              {
                width:bWidth,
                textAlign:"center",
                top: `240px`,
                left: `0px`,
                color: "#1c6764",
                fontSize: "16px",
              },
            ],
          },{
            type: "text",
            text: '门店：'+data.info.title,
            css: [
              {
                width:bWidth,
                textAlign:"center",
                bottom: `70px`,
                left: `0px`,
                color: "#1c6764",
                fontSize: "16px",
              },
            ],
          },
          {
            type: "text",
            text: data.info.times,
            css: [
              {
                width:bWidth,
                top: `${document.documentElement.clientWidth / 2 +60}px`,
                left: `0px`,
                bottom:'30px',
                textAlign:"center",
                color: "#1c6764",
                fontSize: "17px",
              },
            ],
          },
          {
            type: "qrcode",
            content: data.info.codeUrl,
            css: {
              top: `320px`,
              left: `${(document.documentElement.clientWidth-150) / 2}px`,
              color: data.info.has_auth?'#ff0000':"#1c6764",
              borderWidth:"8px",
              borderColor:"#f3f3f3",
              background:"#FFFFFF",
              width: `150px`,
              height: `150px`,
            },
          },
          {
            type: "text",
            text: data.info.tips,
            css: [
              {
                width:bWidth,
                textAlign:"center",
                top: `500px`,
                left: `0px`,
                color: "#1c6764",
                fontSize: "17px",
              },
            ],
          },
        ],
    }
  }

  function poster3(data) {
    return {
        width: bWidth,
        height: bHeight,
        borderRadius:'10px',
        background: '#ffffff',
        views: [
        {
            type: "image",
            url: "/bg/post3.png",
            css: {
              width: bWidth,
              height: bHeight,
              borderRadius:'10px',
              top: "0px",
              bottom:"0px"
            },
          },
          {
            type: "image",
            url: data.info.avatar,
            css: {
              width: `80px`,
              height: `80px`,
              margin: "0 auto",
              top: `100px`,
              left: `${(document.documentElement.clientWidth-80) / 2}px`,
              borderRadius:'100%',
              borderColor:'rgba(255,255,255,0.8)',
              borderWidth:'3px',
            },
          },
          {
            type: "text",
            text: data.info.nickname,
            css: [
              {
                width:bWidth,
                textAlign:"center",
                top: `210px`,
                left: `0px`,
                color: "#0b7387",
                fontSize: "20px",
              },
            ],
          },
          {
            type: "text",
            text: data.info.stitle,
            css: [
              {
                width:bWidth,
                textAlign:"center",
                top: `240px`,
                left: `0px`,
                color: "#0b7387",
                fontSize: "16px",
              },
            ],
          },{
            type: "text",
            text: '门店：'+data.info.title,
            css: [
              {
                width:bWidth,
                textAlign:"center",
                bottom: `70px`,
                left: `0px`,
                color: "#0b7387",
                fontSize: "16px",
              },
            ],
          },{
            type: "text",
            text: data.info.times,
            css: [
              {
                width:bWidth,
                top: `${document.documentElement.clientWidth / 2 +60}px`,
                left: `0px`,
                bottom:'30px',
                textAlign:"center",
                color: "#0b7387",
                fontSize: "17px",
              },
            ],
          },
          {
            type: "qrcode",
            content: data.info.codeUrl,
            css: {
              top: `340px`,
              left: `${(document.documentElement.clientWidth-150) / 2}px`,
              color: data.info.has_auth?'#ff0000':"#26a1b8",
              borderWidth:"8px",
              borderColor:"#ffffff",
              background:"#FFFFFF",
              width: `150px`,
              height: `150px`,
            },
          },
          {
            type: "text",
            text: data.info.tips,
            css: [
              {
                width:bWidth,
                textAlign:"center",
                top: `520px`,
                left: `0px`,
                color: "#f3f3f3",
                fontSize: "17px",
              },
            ],
          },
        ],
    }
  }