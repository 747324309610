<template>
  <div id="app">
    <!-- <van-nav-bar title="标题" left-text="返回" left-arrow>
      <template #right>
        <van-icon name="search" size="18" />
      </template>
    </van-nav-bar> -->

    
    <van-config-provider theme="light">
      <router-view></router-view>
    </van-config-provider>
  </div>
</template>

<script>
import FooterBar from './components/FooterBar.vue'; 
export default {
  name: 'App',
  components: {
    FooterBar
  },
  mounted() {
      // this.getUserInfo()
    },
    methods: {
      getUserInfo(){
        this.$http.postData('/User/getUserInfo',{}).then(res=>{
          localStorage.setItem("userInfo",JSON.stringify(res))
        }).catch(error=>{
        })
      },
    }
}
</script>

<style>
</style>
