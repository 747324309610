<template>
  <div class="hello">
    <row-list
      v-if="redy"
      ref="RowListObj"
      @callBack="onCallBackFun"
      :act="actName"
      :table="tableName"
      :pageTitle="pageTitle"
    ></row-list>
    <van-popup
      v-model:show="showPopup"
      round
      position="bottom"
      :style="{ minHeight: '30%' }"
    >
      <template #default>
        <div class="apps">
          <div class="title van-hairline--bottom">
            【管理】{{ selectItem.nickname }}
          </div>
          <van-grid :column-num="4" :gutter="10" square>
            <van-grid-item
              v-for="(menu, idx) in options"
              :key="idx"
              @click="selectAct(menu)"
            >
              <div class="item">
                <van-icon
                  class="icon"
                  :style="{ color: menu.color }"
                  :name="menu.icon"
                />
                <div>{{ menu.name }}</div>
              </div>
            </van-grid-item>
          </van-grid>
        </div>
      </template>
    </van-popup>
  </div>
</template>
  
<script>
import RowList from "@/components/RowList.vue";
import { showToast, showConfirmDialog } from "vant";
import { ref } from "vue";

export default {
  name: "getList",
  components: {
    RowList,
  },
  data() {
    return {
      loading: false,
      finished: false,
      refreshing: false,
      error: false,
      dataList: [],
      per_page: 20,
      last_page: 0,
      current_page: 0,
      total: 0,
      actName: "",
      tableName: "",
      pageTitle: "",
      userInfo: false,
      uid: 0,
      redy: false,
      showPopup: false,
      options: [],
      selectItem: false,
    };
  },
  setup() {
    const RowListObj = ref < RowList > null;
    return { RowListObj };
  },
  mounted() {
    this.actName =
      this.$route.params.actName != null && this.$route.params.actName
        ? this.$route.params.actName
        : false;
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.uid = this.userInfo ? this.userInfo.uid : 0;

    if (this.actName == "user") {
      this.pageTitle = "我的客户";
      this.tableName = "User";
    } else if (this.actName == "order") {
      this.pageTitle = "我的订单";
      this.tableName = "Order";
    } else if (this.actName == "commission") {
      this.pageTitle = "我的提成";
      this.tableName = "Commission";
    } else if (this.actName == "storeMoney") {
      this.pageTitle = "营业数据";
      this.tableName = "Commission";
    } else {
      showToast("不支持的类型");
      return;
    }
    this.redy = true;
  },
  methods: {
    onCallBackFun(item) {
      if (this.actName == "live") {
        this.$router.push({ name: "makeShare", params: { rid: item.id } });
      } else if (this.actName == "user") {
        // 销售给顾客下单
        localStorage.setItem("selectUser", JSON.stringify(item));
        this.$router.push({ name: "createOrder", params: { uid: item.id } });
      }
    },
    selectAct(opt) {
      if (opt.act == "auth_ok" || opt.act == "auth_cancle") {
        // 进行认证操作
        this.changeAuthStatus("auth", `确认【${opt.name}】吗？`);
      } else if (opt.act == "down_grade" || opt.act == "up_grade") {
        // 进行认证操作
        this.changeAuthStatus("grade", `确认【${opt.name}】吗？`);
      } else if (opt.act == "in_black" || opt.act == "out_black") {
        // 进行认证操作
        this.changeAuthStatus("black", `确认【${opt.name}】吗？`);
      } else if (opt.act == "down_zb" || opt.act == "up_zb") {
        // 设置主播身份
        this.changeAuthStatus("is_zb", `确认【${opt.name}】吗？`);
      } else {
        showToast("不支持的操作");
      }
    },
    changeAuthStatus(field, tips) {
      showConfirmDialog({
        title: "请确认",
        message: tips,
      })
        .then(() => {
          this.$http
            .postData("/User/changeStatus", {
              user_id: this.selectItem.id,
              field: field,
              hide_success: false,
            })
            .then((res) => {
              this.showPopup = false;
              this.RowListObj.onRefresh();
            })
            .catch((error) => {});
        })
        .catch(() => {
          // on cancel
        });
    },
  },
};
</script>
  <style scoped>
.apps {
  padding: 20px 10px;
}
.apps .title {
  text-align: center;
  font-size: 16px;
  color: #666666;
  padding-bottom: 14px;
  margin-bottom: 10px;
}
.apps .item {
  font-size: 12px;
  text-align: center;
  line-height: 24px;
}
.apps .icon {
  font-size: 28px;
}
</style>
  