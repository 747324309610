<template>
  <div
    class="conts"
    style="min-height: 100vh; background-color: #f3f3f3; padding-bottom: 60px"
  >
    <van-sticky>
      <van-nav-bar
        :title="pageTitle"
        left-text="返回"
        left-arrow
        @click-left="goBack"
      >
      </van-nav-bar>
    </van-sticky>
    <van-cell-group inset title="基本信息" label-width="auto">
      <van-field
        label="订单来源"
        v-model="saveData.from_name"
        placeholder="选择来源"
        readonly
        is-link
        input-align="right"
        @click="showFromPopup = true"
      />
      <van-field label="所属用户" input-align="right">
        <template #input>
          <span v-if="selectUser"
            >{{ selectUser.nickname }}
            <van-tag type="success">{{ selectUser.store_title }}</van-tag></span
          >
          <span v-else>未找到</span>
        </template>
      </van-field>
      <van-cell title="服装" :value="saveData.clothes + '套'" />
      <van-cell title="底片" :value="saveData.pic_num + '张'" />
      <van-cell title="精修" :value="saveData.ps_num + '张'" />
      <van-cell title="总金额" :value="saveData.base_price + '元'" />
      <van-field
        label="订单备注"
        type="textarea"
        rows="2"
        v-model="saveData.remark"
        placeholder="请填写备注"
        maxlength="50"
        show-word-limit
      />
    </van-cell-group>
    <van-cell-group
      v-for="(item, idx) in saveData.clients"
      :key="idx"
      inset
      :title="'客户' + (idx + 1)"
      label-width="auto"
    >
      <template #title>
        <span style="line-height: 24px">客户{{ idx + 1 }}</span>
        <van-button
          size="mini"
          @click="delClient(idx)"
          type="default"
          style="float: right"
          >删除</van-button
        >
      </template>
      <van-field
        label="称呼"
        type="text"
        v-model="item.name"
        input-align="right"
        maxlength="8"
        placeholder="请填写称呼"
      />

      <van-field
        label="联系电话"
        type="digit"
        input-align="right"
        v-model="item.mobile"
        maxlength="11"
        placeholder="请填写电话"
      />
      <van-cell title="是否化妆">
        <template #value>
          <van-switch
            v-model="item.need_makeup"
            :active-value="1"
            size="small"
            :inactive-value="0"
          />
        </template>
      </van-cell>
      <van-field label="服装套数" input-align="right">
        <template #input>
          <van-stepper
            v-model="item.clothes"
            :min="0"
            :max="100"
            @change="changeValue"
          />
          套
        </template>
      </van-field>
      <van-field label="保底拍摄" input-align="right">
        <template #input>
          <van-stepper
            v-model="item.pic_num"
            :min="0"
            :max="1000"
            @change="changeValue"
          />
          张
        </template>
      </van-field>
      <van-field label="精修张数" input-align="right">
        <template #input>
          <van-stepper
            v-model="item.ps_num"
            :min="0"
            :max="1000"
            @change="changeValue"
          />
          张
        </template>
      </van-field>
      <van-field label="金额" input-align="right">
        <template #input>
          <van-stepper
            v-model="item.price"
            :min="0"
            :max="9999999"
            @change="changeValue"
          />元
        </template>
      </van-field>
    </van-cell-group>
    <div
      style="
        text-align: center;
        line-height: 20px;
        color: #666666;
        font-size: 12px;
        margin: 10px;
      "
      v-if="!this.saveData.clients || !this.saveData.clients.length"
    >
      请添加客户信息
    </div>

    <div style="margin: 20px 40px">
      <van-button size="small" block type="warning" @click="addClient"
        >添加客户</van-button
      >
    </div>

    <div class="btnbox">
      <van-button type="primary" native-type="submit" @click="toSave"
        >提交订单</van-button
      >
      <van-button type="default" style="margin-left: 20px" @click="goBack"
        >取 消</van-button
      >
    </div>
    <!-- 选择订单来源 -->
    <van-popup
      v-model:show="showFromPopup"
      position="bottom"
      :style="{ minHeight: '30%' }"
    >
      <van-picker title="选择来源" :columns="fromArr" @confirm="selectFrom" />
    </van-popup>
    <!-- 选择拍摄时间 -->
    <!-- <van-field
          label="拍摄日期"
          v-model="saveData.date"
          placeholder="选择日期"
          readonly
          @click="showPopup = true"
        />
    <van-popup v-model:show="showPopup" position="bottom">
      <van-date-picker
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="onConfirm"
        @cancel="showPopup = false"
      />
    </van-popup> -->
  </div>
</template>
<script>
import { showToast, showConfirmDialog } from "vant";
import { ref } from "vue";
export default {
  name: "createOrder",
  data() {
    return {
      loading: false,
      selectUser: false,
      minDate: false,
      maxDate: false,
      showPopup: false,
      showFromPopup: false,
      pageTitle: "创建订单",
      saveData: {
        uid: 0,
        clothes: 0,
        ps_num: 0,
        pic_num: 0,
        add_num: 0, //加精修张数
        base_price: 0, //套餐价格
        add_price: 0, //加精修
        total: 0, //总价格
        remark: "",
        clients: [],
        from: "offline",
        from_name: "地推",
      },
      fromArr: [
        {
          text: "线上",
          value: "online",
        },
        {
          text: "地推",
          value: "offline",
        },
        {
          text: "进店咨询",
          value: "store",
        },
        {
          text: "抖音",
          value: "douyin",
        },
        {
          text: "小红书",
          value: "redbook",
        },
        {
          text: "朋友圈",
          value: "wechat",
        },
        {
          text: "转介绍",
          value: "introduce",
        },
        {
          text: "其他",
          value: "else",
        },
      ],
    };
  },
  setup(props, { emit }) {
    const isOverSize = (file) => {
      const maxSize = file.type === "image/jpeg" ? 1 : 2;
      return file.size >= maxSize;
    };
    // 返回布尔值
    const beforeRead = (file) => {
      if (!(file.type == "image/jpeg" || file.type == "image/png")) {
        showToast("请上传 jpg或png 格式图片");
        return false;
      }
      return true;
    };
    return { isOverSize, beforeRead };
  },
  mounted() {
    this.uid =
      this.$route.params.uid != null && this.$route.params.uid
        ? this.$route.params.uid
        : 0;
    if (!this.uid) {
      showToast("缺少参数");
      setTimeout(function () {
        this.$router.go(-1);
      }, 800);
      return;
    }
    this.selectUser = JSON.parse(localStorage.getItem("selectUser"));
    this.saveData.uid = this.selectUser.id;
    this.saveData.sale_uid = this.selectUser.share_id;
    this.saveData.store_id = this.selectUser.store_id;

    this.changeValue();
    // let year = new Date().getFullYear();
    // let month = new Date().getMonth();
    // let day = new Date().getDay();
    // this.minDate = new Date(year, month, day);
    // this.maxDate = new Date(year + 1, month, day);
  },
  methods: {
    selectFrom(val) {
      this.saveData.from = val.selectedOptions[0].value;
      this.saveData.from_name = val.selectedOptions[0].text;
      this.showFromPopup = false;
    },
    changeValue(val) {
      let ps_num = 0;
      let pic_num = 0;
      let price = 0;
      let clothes = 0;

      this.saveData.clients.map((item) => {
        console.log(item);
        ps_num += item.ps_num;
        pic_num += item.pic_num;
        price += item.price;
        clothes += item.clothes;
      });
      console.log(val);
      this.saveData.ps_num = ps_num;
      this.saveData.pic_num = pic_num;
      this.saveData.base_price = price;
      this.saveData.clothes = clothes;
    },
    addClient() {
      this.saveData.clients.push({
        name: "",
        mobile: "",
        clothes: 0, //服装
        need_makeup: 1, //是否需要化妆
        price: 0, //金额
        pic_num: 0, //拍摄
        ps_num: 0, //精修
        makeup_name: "",
        makeup_uid: "",
        shoot_name: "",
        shoot_uid: "",
        choose_name: "",
        choose_uid: "",
      });
      this.changeValue();
    },
    delClient(idx) {
      const beforeClose = (action) =>
        new Promise((resolve) => {
          resolve(action);
        });
      // 加一个确认
      showConfirmDialog({
        title: "提示",
        message: "确认删除该用户吗？",
        beforeClose,
      })
        .then((val) => {
          this.saveData.clients.splice(idx, 1);
          this.changeValue();
        })
        .catch((e) => {
          console.log(e);
        });
    },

    toSave() {
      if (this.loading) {
        console.log("操作过快");
        return;
      }
      if (!this.saveData.from) {
        showToast("请选择订单来源");
        return;
      }
      if (!this.saveData.base_price) {
        showToast("请设置套餐金额");
        return;
      }
      if (!this.saveData.clients || this.saveData.clients.length < 1) {
        showToast("至少填写一名客户信息");
        return;
      }
      // 检查信息是否填写完整

      try {
        this.saveData.clients.forEach((item, idx) => {
          if (item.name == "") {
            throw new Error("客户" + (idx + 1) + ",称呼不能为空");
          }
          if (item.mobile == "") {
            throw new Error("客户" + (idx + 1) + ",电话不能为空");
          }
        });
      } catch (error) {
        showToast(error.message);
        return;
      }

      this.loading = true;
      let _this = this;
      this.$http
        .postData("/Order/createOrder", {
          hide_success: false,
          ...this.saveData,
        })
        .then((res) => {
          this.loading = false;
          setTimeout(function () {
            window.location.href = "/workSpace/sale";
          }, 1000);
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    // onConfirm(val) {
    //   let str = `${val.selectedValues.join("-")}`;
    //   this.saveData.date = str;
    //   this.showPopup = false;
    // },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
  <style scoped>
.btnbox {
  position: fixed;
  z-index: 2;
  left: 20px;
  right: 20px;
  bottom: 10px;
  text-align: center;
}
</style>