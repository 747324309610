import {createRouter,createWebHashHistory,createWebHistory,useRouter} from "vue-router"
import { showToast } from 'vant';
import Home from "../views/Home"
import Login from "../views/Login"
import createOrder from "../views/createOrder"
import orderDetail from "../views/orderDetail"
import workSpace from "../views/workSpace"
import viewData from "../views/viewData/index"
import addPrice from "../views/addPrice"
import endOrder from "../views/endOrder"

// import makeShare from "../views/makeShare"
import getList from "../views/getList"

const routes=[{
    name:'Home',
    path:'/',
    component:Home,
    meta:{
        title:'旅拍管理',
        auth:true
    }
},{
    name:'rowList',
    path:'/:actName/getList',
    component:getList,
    meta:{
        title:'数据列表',
        auth:true
    }
},{
    name:'createOrder',
    path:'/createOrder/:uid',
    component:createOrder,
    meta:{
        title:'创建订单',
        auth:true
    }
},{
    name:'addPrice',
    path:'/addPrice/:userType/:oid',
    component:addPrice,
    meta:{
        title:'添加项目',
        auth:true
    }
},{
    name:'endOrder',
    path:'/endOrder/:userType/:oid',
    component:endOrder,
    meta:{
        title:'结束订单',
        auth:true
    }
},{
    name:'workSpace',
    path:'/workSpace/:actName',
    component:workSpace,
    meta:{
        title:'工作台',
        auth:true
    }
},{
    name:'viewData',
    path:'/viewData/:dataType',
    component:viewData,
    meta:{
        title:'数据',
        auth:true
    }
},{
    name:'orderDetail',
    path:'/detail/:userType/:oid',
    component:orderDetail,
    meta:{
        title:'订单详情',
        auth:true
    }
},{
    name:'login',
    path:'/login',
    component:Login,
    meta:{
        title:'登陆',
        auth:false
    }
}]

const router = createRouter({
    // history:createWebHashHistory(),
    history:createWebHistory(),
    routes
})
router.beforeEach((to,from,next) =>{
    let userInfo = localStorage.getItem('userInfo')
    userInfo = userInfo?JSON.parse(userInfo):false
    let token = userInfo ? userInfo.token : false
    let nowTime = new Date().getTime()
    let auth_url = 'https://mi.cqcjw.com/wechat/Auth/authorize'
    let tmpUrl = localStorage.getItem('redirect_url')
    if(to.meta.auth){
        if(!token || userInfo.exp >= nowTime){
            if(!tmpUrl || tmpUrl!=location.href){
                localStorage.setItem('redirect_url',location.href)
            }
            localStorage.removeItem('userInfo')
            showToast('请登陆')
            next({
                name: 'login'
            })
            return
        }else{
            next()
        }
    }else{
        if (to.name=='login' && token ) {
            next({
                path: '/'
            })
            // showToast('已经登陆过')
            setTimeout(() => {
                next({
                    path: '/'
                })
            }, 500);
        }else{
            next()
        }
    }
})
router.afterEach(to => {
    // 更改标题
    const titleText = to.meta.title
    // const processTitle = process.env.VUE_APP_TITLE || '金易天成'
    // window.document.title = `${processTitle}${titleText ? ` | ${titleText}` : ''}`
    window.document.title = titleText
  })

export default router
  
  