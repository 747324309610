<template>
  <div
    class="conts"
    style="min-height: 100vh; background-color: #f3f3f3; padding-bottom: 60px"
  >
    <van-sticky>
      <van-nav-bar
        :title="pageTitle"
        left-text="返回"
        left-arrow
        @click-left="goBack"
      >
      </van-nav-bar>
    </van-sticky>
    <template v-if="ready">
      <van-notice-bar
        left-icon="volume-o"
        text="提前结束订单时系统将清除原有已生成的提成记录，必须手动设置全部员工的提成。"
      />
      <van-tabs v-model:active="activeTab">
        <van-tab name="base" title="订单信息">
          <van-cell-group inset label-width="80" style="margin-top: 10px">
            <van-cell title="订单号" :value="orderDetail.order_sn"></van-cell>
            <van-cell
              title="所属门店"
              :value="orderDetail.store_title"
            ></van-cell>
            <van-cell title="当前已收">
              <template #value>
                <span class="red">￥{{ orderDetail.total }}</span>
              </template>
            </van-cell>
            <van-field
              label="实收金额"
              input-align="right"
              placeholder="实收金额,可为0"
              v-model="saveData.act_total"
            ></van-field>
            <van-field
              label="备注"
              type="textarea"
              rows="2"
              v-model="saveData.remark"
              placeholder="请填写备注"
              maxlength="50"
              input-align="right"
              show-word-limit
            />
          </van-cell-group>
        </van-tab>
        <van-tab name="money" title="提成设置">
          <van-cell-group
            v-for="(item, idx) in saveData.tcList"
            :key="idx"
            inset
            label-width="auto"
          >
            <template #title>
              <span style="line-height: 24px">提成 {{ idx + 1 }}</span>
              <van-button
                size="mini"
                @click="delTc(idx)"
                type="default"
                style="float: right"
                >删除</van-button
              >
            </template>
            <van-field
              label="员工"
              v-model="item.worker_name"
              placeholder="选择员工"
              readonly
              is-link
              input-align="right"
              @click="showPopupOne('worker', idx)"
            />
            <van-field
              label="提成类别"
              v-model="item.type_name"
              placeholder="选择类别"
              readonly
              is-link
              input-align="right"
              @click="showPopupOne('type', idx)"
            />
            <van-field label="金额" input-align="right"
              ><template #input>
                <van-stepper
                  v-model="item.amount"
                  :min="10"
                  :max="9999999"
                  @change="jsTc"
                /> </template
            ></van-field>
          </van-cell-group>
          <div
            style="
              text-align: center;
              line-height: 20px;
              color: #666666;
              font-size: 12px;
              margin: 10px;
            "
            v-if="!this.saveData.list || !this.saveData.list.length"
          >
            请添加提成信息
          </div>

          <div style="margin: 20px 40px">
            <van-button size="small" block type="warning" @click="addTc"
              >添加提成</van-button
            >
          </div>
        </van-tab>
      </van-tabs>

      <div class="btnbox">
        <van-button
          type="danger"
          size="small"
          native-type="submit"
          @click="saveBefore"
          >结束订单</van-button
        >
        <van-button
          type="default"
          size="small"
          style="margin-left: 20px"
          @click="goBack"
          >取 消</van-button
        >
      </div>
    </template>
    <template v-else>
      <div
        style="
          text-align: center;
          height: 100px;
          line-height: 100px;
          color: #999;
        "
      >
        请稍后
      </div>
    </template>

    <!-- 选择员工 -->
    <!-- 选择提成类别 -->
    <van-popup
      v-model:show="showPopup"
      position="bottom"
      :style="{ minHeight: '30%' }"
    >
      <van-picker
        :title="selectTitle"
        :columns="tmpArr"
        @confirm="selectBack"
      />
    </van-popup>
  </div>
</template>
<script>
import { showToast, showConfirmDialog } from "vant";
import { ref } from "vue";
export default {
  name: "endOrder",
  data() {
    return {
      loading: false,
      ready: false,
      activeTab: "base",
      orderDetail: false,
      showPopup: false,
      pageTitle: "结束订单",
      selectTitle: "",
      selectIdx: "",
      nowAct: "",
      saveData: {
        oid: 0,
        act_total: "", //实收
        reback: 0, //退款
        store_id: 0,
        remark: "",
        tcList: [],
      },
      filters: false,
    };
  },
  mounted() {
    this.oid =
      this.$route.params.oid != null && this.$route.params.oid
        ? this.$route.params.oid
        : 0;
    if (!this.oid) {
      showToast("缺少参数");
      setTimeout(function () {
        this.$router.go(-1);
      }, 800);
      return;
    }
    this.getOrderDetail();
  },
  methods: {
    getFilter(store_id = 0) {
      this.$http
        .postData("/Commission/getFilter", {
          store_id: store_id,
        })
        .then((res) => {
          this.loading = false;
          this.filters = res;
          this.ready = true;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    getOrderDetail() {
      this.loading = true;
      let _this = this;
      this.$http
        .postData("/Order/getDetail", {
          userType: "store",
          oid: this.oid,
        })
        .then((res) => {
          this.loading = false;
          this.orderDetail = res;
          this.saveData.store_id = res.store_id;
          this.saveData.oid = res.id;
          this.getFilter(res.store_id);
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    showPopupOne(act, idx) {
      console.log(act, idx);
      this.nowAct = act;
      this.selectIdx = idx;

      if (act == "worker") {
        this.tmpArr = this.filters.workerArr;
        this.selectTitle = "选择员工";
      } else if (act == "type") {
        this.tmpArr = this.filters.typeArr;
        this.selectTitle = "选择提成类别";
      }
      this.showPopup = true;
    },
    selectBack(val) {
      let obj = val.selectedOptions[0];
      let idx = this.selectIdx;

      if (this.nowAct == "worker") {
        this.saveData.tcList[idx].worker_id = obj.value;
        this.saveData.tcList[idx].worker_name = obj.text;
      } else if (this.nowAct == "type") {
        this.saveData.tcList[idx].type = obj.value;
        this.saveData.tcList[idx].type_name = obj.text;
      }
      this.showPopup = false;
      this.tmpArr = [];
      console.log(this.saveData.tcList);
    },
    addTc() {
      this.saveData.tcList.push({
        worker_id: 0,
        worker_name: "",
        order_id: this.oid,
        type: "",
        type_name: "",
        amount: "",
      });
      this.jsTc();
    },
    delTc(idx) {
      const beforeClose = (action) =>
        new Promise((resolve) => {
          resolve(action);
        });
      // 加一个确认
      showConfirmDialog({
        title: "提示",
        message: "确认删除提成记录吗？",
        beforeClose,
      })
        .then((val) => {
          this.saveData.tcList.splice(idx, 1);
          this.jsTc();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    jsTc() {
      // 计算总提成费用
      let total = 0;
      let _this = this;
      setTimeout(() => {
        _this.saveData.tcList.filter((item) => {
          total += item.amount;
        });
        console.log(total);
        _this.saveData.commission = total;
      }, 200);
    },
    saveBefore() {
      const beforeClose = (action) =>
        new Promise((resolve) => {
          resolve(action);
        });
      // 加一个确认
      showConfirmDialog({
        title: "提示",
        message: "确认结束订单吗？原有提成记录将清空",
        beforeClose,
      })
        .then((val) => {
          this.toSave();
        })
        .catch((e) => {
          console.log(e);
        });
    },

    toSave() {
      if (this.loading) {
        console.log("操作过快");
        return;
      }
      if (this.saveData.act_total == "") {
        showToast("请填写实收金额，可填写0");
        return;
      }
      if (!this.saveData.remark) {
        showToast("请填写操作备注");
        return;
      }

      try {
        this.saveData.tcList.forEach((item, idx) => {
          if (parseInt(item.worker_id) < 1) {
            throw new Error("提成" + (idx + 1) + ",请指定员工");
          }
          if (item.type == "") {
            throw new Error("提成" + (idx + 1) + ",请指定类别");
          }
        });
      } catch (error) {
        showToast(error.message);
        return;
      }
      this.loading = true;
      let _this = this;
      this.$http
        .postData("/Order/endOrder", {
          ...this.saveData,
          hide_success: false,
        })
        .then((res) => {
          this.loading = false;
          setTimeout(function () {
            _this.goBack();
          }, 1000);
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
  <style scoped>
.btnbox {
  position: fixed;
  z-index: 2;
  left: 20px;
  right: 20px;
  bottom: 10px;
  text-align: center;
}
.red {
  color: #cc0000;
}
</style>