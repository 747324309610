import request from './http'

const apis={
    getPushUrl:(data)=>{
        data = !data?{}:data
        const url = '/Caster/makeUrl'
        return request({
            url: url,
            method: 'post',
            data,
            hide_loading:data.hide_loading?data.hide_loading:false,
            hide_success:data.hide_success?data.hide_success:false,
            hide_error:data.hide_error?data.hide_error:false,
        })
    },
    sendData:(data)=>{
        data = !data?{}:data
        const url = '/test'
        return request({
            url: url,
            method: 'post',
            data
        })
    },login:(data)=>{
        data = !data?{}:data
        const url = '/Auth/loginByCode'
        return request({
            url: url,
            method: 'post',
            data,
            hide_loading:true
        })
    },loginByUid:(data)=>{
        data = !data?{}:data
        const url = '/Auth/loginByUid'
        return request({
            url: url,
            method: 'post',
            data,
            hide_loading:true
        })
    },makeShareUrl:(data)=>{
        data = !data?{}:data
        const url = '/Auth/makeShareUrl'
        return request({
            url: url,
            method: 'post',
            data,
            hide_loading:true,
            hide_success:true
        })
    },bindShare:(data)=>{
        data = !data?{}:data
        const url = '/Share/bindShare'
        return request({
            url: url,
            method: 'post',
            data,
            hide_loading:true,
            hide_error:true
        })
    },getDetail:(data)=>{
        data = !data?{}:data
        const url = `/${data.table}/getDetail`
        return request({
            url: url,
            method: 'post',
            data,
            hide_success:true
        })
    },getPushDetail:(data)=>{
        data = !data?{}:data
        const url = `/${data.table}/getPushDetail`
        return request({
            url: url,
            method: 'post',
            data,
            hide_success:true
        })
    },getList:(data)=>{
        data = !data?{}:data
        const url = `/${data.table}/getList`
        return request({
            url: url,
            method: 'post',
            data,
            hide_loading:data.hide_loading?data.hide_loading:false,
            hide_success:data.hide_success?data.hide_success:false,
            hide_error:data.hide_error?data.hide_error:false,
        })
    },answerQuestion:(data)=>{
        data = !data?{}:data
        const url = '/Question/answerQuestion'
        return request({
            url: url,
            method: 'post',
            data
        })
    },sendComment:(data)=>{
        data = !data?{}:data
        const url = '/Comment/sendComment'
        return request({
            url: url,
            method: 'post',
            data,
            hide_loading:data.hide_loading?data.hide_loading:false,
            hide_success:data.hide_success?data.hide_success:false,
            hide_error:data.hide_error?data.hide_error:false,
        })
    },postData:(url,data)=>{
        data = !data?{}:data
        return request({
            url: url,
            method: 'post',
            data:data,
            hide_loading:data.hasOwnProperty("hide_loading")?data.hide_loading:false,
            hide_success:data.hasOwnProperty("hide_success")?data.hide_success:true,
            hide_error:data.hasOwnProperty("hide_error")?data.hide_error:false,
        })
    },uploadPic:(url,data)=>{
        data = !data?{}:data
        return request({
            url: url,
            method: 'post',
            data:data,
            hide_loading:false,
            hide_success:false,
            hide_error:false,
            upload:true,
        })
    }
}

export default apis