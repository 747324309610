<template>
  <div>
    <div class="msk" @click="onClose('close')"></div>
    <div class="con">
      <vue-canvas-poster
        :widthPixels="900"
        :painting="painting"
        @success="success"
        @fail="fail"
      ></vue-canvas-poster>
      <span class="sx" @click="changeType"
        ><van-icon name="replay" style="font-size: 14px"></van-icon>更换</span
      >
      <img :src="posterImg" style="width: 100%" />
      <div style="margin-top: 10px; font-size: 12px">长按图片保存</div>
    </div>
  </div>
</template>
<script>
import { VueCanvasPoster } from "vue-canvas-poster";
import { drawPoster } from "./poster";

export default {
  components: {
    VueCanvasPoster,
  },
  props: {
    info: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      n: this.getRandomInt(1, 3),
      max: 3,
      painting: {},
      posterImg: "",
    };
  },
  computed: {},
  setup(props, { emit }) {
    function onClose(val) {
      emit("onClose", val);
    }
    return { onClose };
  },
  mounted() {
    console.log(this.info.codeUrl);
    this.info.avatar += "?any_";
    const params = {
      type: this.n,
      info: this.info,
    };
    drawPoster(params).then((res) => {
      this.painting = res;
    });
  },
  methods: {
    getRandomInt(min, max) {
      min = Math.ceil(min); // 确保min是整数
      max = Math.floor(max); // 确保max是整数
      return Math.floor(Math.random() * (max - min + 1)) + min; // 返回介于min和max之间的随机整数
    },
    changeType() {
      this.n += 1;
      if (this.n > this.max) {
        this.n = 1;
      }
      drawPoster({
        type: this.n,
        info: this.info,
      }).then((res) => {
        this.painting = res;
      });
    },
    success(src) {
      this.posterImg = src;
    },
    fail(err) {
      console.log("fail", err);
    },
  },
};
</script>
  <style scoped>
.msk {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.con {
  position: fixed;
  z-index: 20000;
  top: 40px;
  left: 40px;
  right: 40px;
  height: auto;
  color: #fff;
  text-align: center;
}
.sx {
  display: block;
  height: 30px;
  padding: 0 6px;
  background: rgba(0, 0, 0, 0.5);
  color: #f2f2f2;
  font-size: 12px;
  line-height: 30px;
  text-align: center;
  position: absolute;
  z-index: 2002;
  right: 20px;
  top: 20px;
  border-radius: 6px;
}
</style>  