<template>
  <div>
    <template v-if="ready">
      <!-- 运营数据 -->
      <order-data
        pageTitle="运营数据"
        v-if="dataType == 'store'"
        :detail="contentDetail"
      ></order-data>
      <!-- 查看红包记录 -->
      <commission-data
        pageTitle="提成数据"
        v-if="dataType == 'commission'"
        :detail="contentDetail"
      ></commission-data>
    </template>
    <div
      style="
        text-align: center;
        height: 90px;
        line-height: 90px;
        color: #999999;
      "
      v-else
    >
      请稍后
    </div>
  </div>
</template>
  
<script>
import orderData from "./orderData.vue";
import commissionData from "./commissionData.vue";
import { showToast } from "vant";
export default {
  name: "viewData",
  components: {
    orderData,
    commissionData,
  },
  data() {
    return {
      activeName: "",
      contentDetail: {},
      ready: false,
      userOptions: [],
      showUserAction: false,
      selectItem: false,
      dataType: false,
      tips: false,
    };
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.tips = false;
    this.dataType =
      this.$route.params.dataType != null && this.$route.params.dataType
        ? this.$route.params.dataType
        : false;
    if (!this.dataType) {
      showToast("参数错误");
      return;
    }
    this.ready = true;
  },
  methods: {},
};
</script>
  <style scoped>
.hello {
}
</style>
  