<template>
  <div class="footer_bar">
    <!-- <van-tabbar v-model="active">
      <van-tabbar-item icon="wap-home-o" name="home" @click="toPage('home')">首页</van-tabbar-item>
      <van-tabbar-item icon="video-o" name="detail" @click="toPage('detail')">资源</van-tabbar-item>
      <van-tabbar-item icon="user-o" name="account" @click="toPage('account')">我的</van-tabbar-item>
    </van-tabbar> -->
  </div>
</template>

<script>
import {watchEffect} from 'vue'
import { useRoute } from 'vue-router'
export default {
  name: 'FooterBar',
  props: {
    msg: String
  },
  data(){
    return {
      active:''
    }
  },
  mounted() {
    const route = useRoute()
    watchEffect(() => {
        this.active = route.name
    })
  },
  methods: {
    toPage(name){
      this.active = name
      this.$router.push({name:name})
    }
  },
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
